import React from 'react';
import './MockPanel.scss';
import classNames from "classnames";

const MockPanel = ({ className, children, ...props }) => {

    return (
        <div className={classNames("MockPanel", className)} {...props}>
            {children}
        </div>
    );
};

export default MockPanel;
