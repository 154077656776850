import LocalHttpApi from "./LocalHttpApi";

const controllerPrefix = '/time-zones';
const localHttpApi = LocalHttpApi.getInstance();

/**
 * Retrieves the list of zones from the server.
 *
 * @returns {Promise<any>} - A promise that resolves with the list of zones retrieved from the server.
 */
export async function getZones() {
    return localHttpApi.callApi({
        path: controllerPrefix,
        method: 'GET',
    });
}

/**
 * Retrieves time zones by zone.
 *
 * @param {string} zone - The zone for which to retrieve time zones. Default is 'Mexico'.
 * @returns {Promise<*>} - A Promise that resolves with the response from the localHttpApi call.
 */
export async function getTimeZonesByZone({zone = 'Mexico'}) {
    return localHttpApi.callApi({
        path: `${controllerPrefix}/${zone}`,
        method: 'GET',
    });
}

/**
 * Retrieves the current time zone from the server.
 *
 * @returns {Promise<*>} A promise that resolves with the current time zone.
 *                      The resolved value can vary depending on the server's response.
 *                      Make sure to handle any errors that may occur.
 */
export async function getCurrentTimeZone() {
    return localHttpApi.callApi({
        path: `${controllerPrefix}/config/current`,
        method: 'GET',
    });
}

/**
 * Sets the current time zone for the application.
 *
 * @param {string} timeZone - The time zone to be set.
 * @returns {Promise<*>} - A promise that resolves when the time zone has been set.
 */
export async function setCurrentTimeZone({timeZone}) {
    return localHttpApi.callApi({
        path: `${controllerPrefix}/config/current`,
        method: 'PUT',
        params: {timeZone},
    });
}