import LocalWsApi from "../LocalWsApi";

const controllerPrefix = "qrReader/";

const qrEvents = {
  READ: "qrReader.read",
};

export default class QrReaderClient {
  constructor() {
    this.wsApi = LocalWsApi.getInstance();
    this.listeners = [];
  }

  async getStatus() {
    return this.wsApi.callApi({
      path: controllerPrefix + "getStatus",
    });
  }

  async startRead() {
    return this.wsApi.callApi({
      path: controllerPrefix + "startRead",
    });
  }
  async stopRead() {
    return this.wsApi.callApi({
      path: controllerPrefix + "stopRead",
    });
  }
  handleRead(qrData) {
    this.listeners.forEach((listener) => {
      listener(qrData);
    });
  }

  onRead(listener) {
    this.startRead();
    this.listeners.push(listener);
    if (this.listeners.length === 1) {
      this.wsApi.subscribe(qrEvents.READ, this.handleRead.bind(this));
    }
  }

  offRead(listener) {
    this.stopRead();
    this.listeners = this.listeners.filter((l) => l !== listener);
    if (this.listeners.length === 0) {
      this.wsApi.unsubscribe(qrEvents.READ, this.handleRead.bind(this));
    }
  }

  removeAllListeners() {
    if (this.listeners.length > 0) {
      this.wsApi.unsubscribe(qrEvents.READ, this.handleRead.bind(this));
      this.listeners = [];
    }
  }
}
