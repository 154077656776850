import './ShadowAlert.scss'
import logo from "../../assets/images/loading.gif";
import {useEffect} from "react";

export default function ShadowAlert({onClose, title, message, icon, duration = 5000}) {

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, duration)
  }, [duration, onClose])

  return (
    <div className="ShadowAlert">
      <div className="alertContent">
        <div className="logo">
          <img src={icon || logo} alt="icon"/>
        </div>
        <div className={'title'}>
          {title || 'This is a title'}
        </div>
        <div className={'message'}>
          {message || 'This is a message'}
        </div>
      </div>
    </div>
  )
}
