import React from 'react';

const TimeZoneRow = ({zone, isSelected = false, onClick}) => {
    const selectedClass = isSelected ? 'selected' : '';
    return <div className={`zone-row ${selectedClass}`} onClick={() => {
        onClick?.(zone)
    }}>
        <span>{zone}</span>
    </div>;
};

export default TimeZoneRow;
