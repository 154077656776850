import React, { useCallback, useEffect, useState } from 'react'
import "./ToggleLanguage.scss"
import { changeLanguage } from '../../i18n/config';
import { useTranslation } from 'react-i18next';

export default function ToggleLanguage() {

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState('es')

  const toggleLanguage = useCallback(() => {
    if (language === 'es') {
      setLanguage('en');
      changeLanguage('en');
    }
    if (language === 'en') {
      setLanguage('es');
      changeLanguage('es');
    }
  }, [language]);

  useEffect(() => {
    if (i18n.language) {
      setLanguage(i18n.language)
    }
  }, [i18n.language])

  return (
    <div
      className='ToggleLanguage'
      onClick={toggleLanguage}
    >
      <div className='buttons-container'>
        <div className={`button-english button-text ${language === 'en' ? 'text-active' : 'text-inactive'}`}>
          {t('English')}
        </div>
        <div className={`button-spanish button-text ${language === 'es' ? 'text-active' : 'text-inactive'}`}>
          {t('Spanish')}
        </div>
        <div className={`button-active ${language === 'en' ? 'english-active' : 'spanish-active'}`}></div>
      </div>
    </div>
  )
}
