import React, {createContext, useCallback, useState} from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    const addAlert = useCallback(( alert ) => {

        const id = Date.now();
        const timeout = alert.timeout || 5000;

        setAlerts((alerts) => [
            ...alerts,
            {id, ...alert},
        ]);

        // Automatically remove the alert after 5 seconds
        setTimeout(() => {
            setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
        }, timeout);

        return id;
    }, []);

    const removeAlert = useCallback( (id) => {
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
    }, []);

    return (
        <AlertContext.Provider value={{ alerts, addAlert, removeAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
