import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();


export function btmLog( str ) {
    return localHttpApi.callApi({
        path: "/log",
        method: "POST",
        params: { log: str },
    });
}
