import React, { useCallback, useEffect, useState } from 'react'
import "./Terms.scss"
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import {useLocation, useNavigate} from 'react-router-dom';
import { paths } from '../../services/routes/appRoutes';
import Modal from '../../components/Modal/Modal';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Checkbox from '../../components/Checkbox/Checkbox';
import {getSetting, settingKey} from "../../services/appSettings";
import {useSelector} from "react-redux";
import ShadowAlert from "../ShadowAlert/ShadowAlert";

export default function Terms() {

  const { kycData } = useSelector((state) => state.session);
  const isKYC = kycData.kycStatus === 'verified';
  const location = useLocation();
  const nextRoute = location.state?.nextRoute ?? paths.home;
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyAccepted, setPrivacyAccepted] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [terms, setTerms] = useState('')
  const [privacy, setPrivacy] = useState('')
  const [first, setFirst] = useState(true)
  const [showVerificationSuccessAlert, setShowVerificationSuccessAlert] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const getTerms = useCallback(async () => {
    let setting = await getSetting(settingKey.TERMS)
    if (setting) {
      setTerms(setting);
    }
  }, []);

  const getPrivacy = useCallback(async () => {
    let setting = await getSetting(settingKey.PRIVACY)
    if (setting) {
      setPrivacy(setting);
    }
  }, []);

  const handleShowTerms = useCallback(() => {
    setShowTerms(true);
  }, [])

  const handleShowPrivacy = useCallback(() => {
    setShowPrivacy(true);
  }, [])

  const onCloseAlert = useCallback(() => {
    setShowVerificationSuccessAlert(false);
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  const handleContinue = useCallback(() => {
    if (isKYC) {
      setShowVerificationSuccessAlert(true);
    } else {
      navigate(nextRoute);
    }
  }, [navigate, nextRoute, isKYC])

  const onBack = useCallback(() => {
    navigate(paths.home);
  }, [navigate])

  const onAcceptTerms = useCallback(() => {
    setTermsAccepted(true);
    setShowTerms(false);
  }, [])

  const onAcceptPrivacy = useCallback(() => {
    setPrivacyAccepted(true);
    setShowPrivacy(false);
  }, [])

  const handleChangeTerms = useCallback((e) => {
    setTermsAccepted(e.target.checked);
  }, [])

  const handleChangePrivacy = useCallback((e) => {
    setPrivacyAccepted(e.target.checked);
  }, [])

  useEffect(() => {
    if (first) {
      setFirst(false);
      getTerms();
      getPrivacy();
    }
  }, [first, getPrivacy, getTerms])


  return (
    <Layout
      showHeader
      showFooter
      backButtonCallback={onBack}
    >
      <div className='Terms'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div>
            <Title
              text={t('Terms of use and privacy notice')}
            ></Title>
          </div>
          <div className='subtitle mt-2'>
            {t('Before starting, you need to know the terms of use of our BTMs and how we protect your privacy')}
          </div>
          <div className='mt-5'>
            <Button
              type="secondary"
              onClick={handleShowTerms}
              className="button-terms"
            >
              {t('See terms and conditions')}
            </Button>
          </div>
          <div className='mt-5'>
            <Button
              type="secondary"
              onClick={handleShowPrivacy}
              className="button-terms"
            >
              {t('See privacy policy')}
            </Button>
          </div>
          <div className='mt-5 d-flex'>
            <div className='mr-checkbox'>
              <Checkbox
                checked={termsAccepted}
                onChange={handleChangeTerms}
              ></Checkbox>
            </div>
            <div className='terms-text'>
              {t('I accept Cryptobox terms and conditions')}
            </div>
          </div>
          <div className='mt-5 d-flex'>
            <div className='mr-checkbox'>
              <Checkbox
                checked={privacyAccepted}
                onChange={handleChangePrivacy}
              ></Checkbox>
            </div>
            <div className='terms-text'>
              {t('I accept privacy policy')}
            </div>
          </div>
          <div className='mt-5'>
            <Button
              onClick={termsAccepted && privacyAccepted ? handleContinue : undefined}
              className={(!termsAccepted || !privacyAccepted) ? 'button-continue button-disabled' : 'button-continue'}
            >
              {t('Continue')}
            </Button>
          </div>
        </div>

        {
          showTerms &&
          <Modal>
            <TermsAndConditions
              title={t('Terms and conditions')}
              onClose={() => setShowTerms(false)}
              onAccept={onAcceptTerms}
              content={terms}
            ></TermsAndConditions>
          </Modal>
        }

        {
          showPrivacy &&
          <Modal>
            <TermsAndConditions
              title={t('Privacy policy')}
              onClose={() => setShowPrivacy(false)}
              onAccept={onAcceptPrivacy}
              content={privacy}
            ></TermsAndConditions>
          </Modal>
        }
        {
          showVerificationSuccessAlert &&
          <ShadowAlert
            onClose = {onCloseAlert}
            title = {t('Verified identity')}
            message = {t('Verified phone number continuing the transaction')}
          ></ShadowAlert>
        }
      </div>
    </Layout>
  )
}
