import React, {useEffect} from 'react';
import './MockControl.scss';
import QrMock from "./components/QrMock/QrMock";
import BanknoteRecyclerMock from "./components/BanknoteRecyclerMock/BanknoteRecyclerMock";
import TicketPrinterMock from "./components/TicketPrinterMock/TicketPrinterMock";
import SecurityCamera from "./components/SecurityCamera/SecurityCamera";
import useBodyClass from "../../hooks/useBodyClass";
import {btmLog} from "../../services/localBackendService";

const MockControl = () => {

    useEffect(() => {
        btmLog('MockControl screen').catch(console.error);
    }, []);

    useBodyClass('desktop-view')


    return (
        <div className={"MockControl"}>
            <h1 className=' title'>Hardware mock control</h1>
            <div className='content'>

                <QrMock className={'panel'} />
                <BanknoteRecyclerMock className={'panel'} />
                <TicketPrinterMock className={'panel'} />
                <SecurityCamera className={'panel'} />

            </div>
        </div>
    );
};

export default MockControl;
