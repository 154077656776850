import React from 'react';
import './HistoryPanel.scss';
import classNames from "classnames";

const HistoryPanel = ({ className, children, ...props}) => {

    return (
        <div className={classNames("HistoryPanel", className)} {...props}>
            {children}
        </div>
    );
};

export default HistoryPanel;
