import "./IncreaseLimit.scss"
import Layout from '../../components/Layout/Layout'
import { paths } from '../../services/routes/appRoutes';
import {useLocation, useNavigate} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Title from '../../components/Title/Title';
import CircleTimer from '../../components/CircleTimer/CircleTimer';
import { secondsToTimer } from '../../services/constants';
import QrInfo from '../../components/QrInfo/QrInfo';
import {useCallback, useEffect} from "react";
import {btmLog} from "../../services/localBackendService";

export default function IncreaseLimit() {

    useEffect(() => {
        btmLog('IncreaseLimit screen').catch(console.error);
    }, []);

  const location = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const backRoute = location.state?.backRoute ?? paths.home;
  const nextRoute = location.state?.nextRoute ?? paths.home;
  const showBackButton= location.state?.showBackButton ?? true;

  const onBack = useCallback(() => {
      navigate(backRoute);
  }, [navigate, backRoute]);

  const onUnderstood = useCallback(() => {
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={onBack}
      hideButtons={!showBackButton}
    >
      <div className='IncreaseLimit'>
        <div className='d-flex justify-content-end'>
          <CircleTimer
            seconds={secondsToTimer}
            callback={onBack}
          />
        </div>
        <div className='title text-center title-position'>
            <Title
              text={t('Attention!')}
            ></Title>
        </div>
        <QrInfo
          onClick={onUnderstood}
          instructions={[
            <Trans key={0} components={{ bold: <strong /> }} i18nKey={'Limits for non-registered users'}></Trans>,
            <Trans key={1} components={{ bold: <strong /> }} i18nKey={'Limits for registered users'}></Trans>,
            <Trans key={2} components={{ bold: <strong /> }} i18nKey={'Verification requirements: INE, RFC with homoclave and verify economic activity'}></Trans>,
            <Trans key={3} components={{ bold: <strong /> }} i18nKey={'Verification is only once and is valid for all Cryptobox BTMs in the network'}></Trans>
          ]}
          hideFirstParagraph={true}
        ></QrInfo>
      </div>
    </Layout>
  )
}
