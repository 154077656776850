import React from 'react'
import _ from "lodash";
import "./InstructionsBox.scss"

export default function InstructionsBox(
  {title, list, boxClass, titleClass, lastInfo}
) {

  return (
    <div className='InstructionsBox'>
      <div className={boxClass}>
        <div className='d-flex justify-content-center'>
          <span className={titleClass}>
            {title}
          </span>
        </div>
        <ul className='list'>
          {
            _.map(list, (element, index) => (
              <li key={index}>
                {element}
              </li>
            ))
          }
        </ul>
        {
          lastInfo &&
          <div className='last-info'>
            {lastInfo}
          </div>
        }
      </div>
    </div>
  )
}
