import React, { useCallback, useEffect, useState } from 'react'
import "./HelpInfo.scss"
import btmIcon from "../../assets/images/btm.png"
import versionIcon from "../../assets/images/version.png"
import emailIcon from "../../assets/images/email.png"
import whatsAppIcon from "../../assets/images/whatsapp.png"
import closeModal from "../../assets/images/close-modal.png"
import {getSetting, settingKey} from "../../services/appSettings";
import {btmLog} from "../../services/localBackendService";

export default function HelpInfo(
  {onClose}
) {

  const [whatsAppNumber, setWhatsAppNumber] = useState('56 3450 9121')
  const [emailAddress, setEmailAddress] = useState('info@montebit.io')
  const [version, setVersion] = useState('0.0.0');
  const [btmId, setBtmId] = useState('0');

  const setSetting = useCallback(async (settingKey, setter) => {
    const setting = await getSetting( settingKey );
    if (setting) {
      setter(setting);
    }
  }, []);

  const fetchData = useCallback(async () => {
    await setSetting(settingKey.CONTACT_PHONE, setWhatsAppNumber);
    await setSetting(settingKey.CONTACT_EMAIL, setEmailAddress);
    await setSetting(settingKey.APP_VERSION, setVersion);
    await setSetting(settingKey.BTM_ID, setBtmId);
  }, [setSetting, setWhatsAppNumber, setEmailAddress, setVersion, setBtmId]);

  useEffect( () => {
    fetchData()
        .catch( error => {
            btmLog(`Error on getting setting. msg: ${error?.message || 'Unknown error.'}`).catch(console.error);
        });
  }, [fetchData])
  
  return (
    <div className='HelpInfo'>
      <div className='d-flex close'>
        <div onClick={onClose}>
          <img src={closeModal} alt="" />
        </div>
      </div>
      <div className='d-flex'>
        <div className='icon'>
          <img src={whatsAppIcon} alt="whatsApp" />
        </div>
        <div className='info'>
          {whatsAppNumber}
        </div>
      </div>
      <div className='d-flex mt-2'>
        <div className='icon'>
          <img src={emailIcon} alt="Email" />
        </div>
        <div className='info'>
          {emailAddress}
        </div>
      </div>
      <div className='d-flex mt-2'>
        <div className='icon'>
          <img src={btmIcon} alt="btm" />
        </div>
        <div className='info'>
          BTM: {btmId}
        </div>
      </div>
      <div className='d-flex mt-2'>
        <div className='icon'>
          <img src={versionIcon} alt="version" />
        </div>
        <div className='info'>
          Version: {version}
        </div>
      </div>
    </div>
  )
}
