import React from 'react';
import './TicketMock.scss';
import classNames from "classnames";

const TicketMock = ({ ticketElements }) => {

    const optionsToClasses = ( options )=>{
        const classes = [];
        if(!options) return classes;
        for( let option in options ){
            classes.push( `${option}-${options[option]}` );
        }
        return classes;
    }

    return (
        <pre className={"TicketMock"}>
            {ticketElements.map((element, index) => (
                <div key={index} className={classNames('element', element.type, ...optionsToClasses(element.options))} >
                <span key={index} >
                    {element.type!=='text' && `[${element.type}] `}{element.value}
                </span><br/>
                </div>
            ))}
        </pre>
    );
};

export default TicketMock;
