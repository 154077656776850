import './Alert.scss'
import logo from "../../assets/images/scan_arrow.gif";
import {useEffect} from "react";

export default function Alert(
  {onClose, title, message, icon, duration = 5000, textSize = ''}
) {

  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, duration)
  }, [duration, onClose])

  return (
    <div className="Alert">
      <div className="alertContent">
        <div className="logo">
          <img src={icon || logo} alt="icon"/>
        </div>
        <div className={textSize ? 'title-' + textSize : 'title'}>
          {title || 'This is a title'}
        </div>
        <div className={textSize ? 'message-' + textSize : 'message'}>
          {message || 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'}
        </div>
      </div>
    </div>
  )
}
