import React, {useCallback, useEffect} from 'react'
import './SuccessRedemption.scss'
import { useTranslation } from 'react-i18next';
import insertCash from '../../assets/images/insert_cash.svg'
import Layout from '../../components/Layout/Layout';
import Button from '../../components/Button/Button';
import CircleTimer from '../../components/CircleTimer/CircleTimer';
import { secondsToTimer } from '../../services/constants';
import { paths } from '../../services/routes/appRoutes';
import { useNavigate } from 'react-router-dom';
import {btmLog} from "../../services/localBackendService";

export default function SuccessRedemption() {

  useEffect(() => {
    btmLog('SuccessRedemption screen');
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = useCallback(() => {
    navigate(paths.home);
  }, [navigate])

  const goHome = useCallback(() => {
    navigate(paths.home);
  }, [navigate]);

  return (
    <Layout
      hideButtons={true}
    >
      <div className='SuccessRedemption'>
      <div className='d-flex justify-content-end'>
          <CircleTimer
            seconds={secondsToTimer}
            callback={onBack}
          />
        </div>
        <div className='title mt-title'>
            {t('Thanks for your preference!')}
          </div>
          <div className='d-flex justify-content-center'>
            <div className='success-image'>
              <img src={insertCash} alt="success" />
            </div>
          </div>
          <div className='subtitle mt-subtitle'>
            {t('Withdraw your cash')}
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-content'>
              {t('Successful transaction')} <br />
              {t('Please remove your bills from the dispenser and make sure you do not')} <br />
              {t('leave any behind. We appreciate your trust and preference')}
              {t('Enjoy your cash and come back soon!')}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-button'>
            <Button
              className="button-finish"
              onClick={goHome}
            >
              {t('Finish')}
            </Button>
          </div>
      </div>
    </Layout>
  )
}
