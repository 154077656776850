import React, {useCallback, useContext, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import Rate from '../../components/Rate/Rate';
import { paths } from '../../services/routes/appRoutes';
import "./SellBitcoin.scss"
import Button from '../../components/Button/Button';
import CircleTimer from '../../components/CircleTimer/CircleTimer';
import { moneyFormatter } from '../../services/currencyUtils';
import Layout from '../../components/Layout/Layout';
import Title from '../../components/Title/Title';
import { secondsToTimer } from '../../services/constants';
import {useDispatch, useSelector} from "react-redux";
import {clearSession, setRequestedAmount, setTicketData} from "../../utils/redux/sessionSlice";
import {numberBtcFormat} from "../../utils/btcUtils";
import {createReceiveTransaction, sendReceipt} from "../../services/rayyoClient";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {btmLog} from "../../services/localBackendService";
import { useTranslation } from 'react-i18next';
import CurrentLimit from '../../components/CurrentLimit/CurrentLimit';
import { formatMoney } from '../../utils/numberUtils';
import {stopRecording} from "../../services/localSecurityCameraService";
import {logSessionEvent, setSessionBtmTxId} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

const BANKNOTE_VALUE = 500;//TO_DO: make this configurable

export default function SellBitcoin() {

  useEffect(() => {
    btmLog('SellBitcoin screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {addAlert} = useContext(AlertContext);
  const session = useSelector((state) => state.session);
  const { sellPrice, kycData } = session;
  const [moneyAmount, setMoneyAmount] = useState(0);
  const [disabledSellButton, setDisabledSellButton] = useState(false);
  const currentLimit = kycData.amountLimitRemaining;
  const isKYC = kycData.kycStatus === 'verified';

  const onBack = useCallback(() => {
    dispatch(clearSession());
    navigate(paths.home);
  }, [navigate, dispatch])

  const minusAmount = useCallback(() => {
    setMoneyAmount( oldAmount => {
        const newAmount = oldAmount - BANKNOTE_VALUE;
        return newAmount < 0 ? 0 : newAmount;
    })
  }, []);

  const plusAmount = useCallback(() => {
    setMoneyAmount(oldAmount => {
      let newAmount = oldAmount + BANKNOTE_VALUE;
      if (newAmount > currentLimit) {
        return oldAmount
      }
      return newAmount;
    })
  }, [currentLimit]);

  const bitcoinAmount = moneyAmount / sellPrice;

  const onContinue = async () => {
    if(moneyAmount === 0) {
      addAlert({message: t('You must enter an amount'), type: 'error'});
      return;
    }

    setDisabledSellButton(true);
    let dataTransaction;
    const {sessionId, marketPrice, sellPrice, phoneInfo} = session;
    const mxnAmount = Math.round(moneyAmount * 100);
    const btcAmount = Math.round(bitcoinAmount * 100_000_000);

    try {
      await stopRecording({fireAndForget: false}).catch(console.error);
      const {data} = await createReceiveTransaction({
        sessionId,
        marketPrice,
        phone: phoneInfo.phone,
        mxnAmount,
        btcAmount,
        sellPrice
      });
      // data is like: { address:"bcrt1qez5al8p2mw380ukwtq5md846a28k34tts5h4w0", btmTxId:"dbb8aa8f-6a26-4d56-9d82-7f3520a97c71" }
      dataTransaction = data
    } catch (e) {
      addAlert({message: t('Failed to create transaction'), type: 'error'});
      const errStr = 'Error creating receive address: ' + e?.message || e;
      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "SellBitcoin", critical: true, error: errStr });
      await btmLog(errStr);
      navigate(paths.home);
      return;
    }

    if(dataTransaction?.btmTxId){
      await setSessionBtmTxId(sessionId, dataTransaction.btmTxId);
    }

    logSessionEvent(sessionId, sessionEvents.SELL_SUCCESS, { ...dataTransaction, mxnAmount, btcAmount, sellPrice });

    try {
      await sendReceipt({
        phone: phoneInfo.phone,
        btmTxId: dataTransaction.btmTxId,
        message: t("ReceiptMessage"),
        type: 'sell'
      });
    } catch (e) {
      const errStr = 'Error sending sms receipt: ' + e?.message || e;
      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "SellBitcoin", critical: false, error: errStr });
      await btmLog(errStr);
      addAlert({message: t('Failed to send ticket via sms'), type: 'error'});
    }

    dispatch(setRequestedAmount({amount: moneyAmount, address: dataTransaction.address}));
    dispatch(setTicketData({
      address: dataTransaction.address,
      phone: phoneInfo.phone,
      mxnAmount,
      btcAmount,
      sellPrice,
      id: sessionId
    }));
    navigate(paths.sendBitcoin);
  };

  const onIncrease = () => {
    navigate(paths.increaseLimit, {state: {backRoute: -1}});
  };

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={onBack}
      hideButtons={false}
    >
      <div className='SellBitcoin'>
        <div className='title text-center'>
          <Title
            text={t('Sell_Bitcoin')}
          ></Title>
        </div>
        <div className='d-flex justify-content-between'>
          <Rate
            text={t('Sell')}
            value={sellPrice}
          />
          <CircleTimer
            seconds={secondsToTimer}
            callback={onBack}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <div className='current-limit'>
            <CurrentLimit
              showIcon={true}
              showButton={!isKYC}
              onIncrease={onIncrease}
            >
              {t('Current limit is: ', {amount: '$' + formatMoney(currentLimit)})}
            </CurrentLimit>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='description'>
            <span>
              {t('Choose the amount in pesos you want to sell, then its equivalent in BTC will be shown')}
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <div className='box-content'>
            <div className='d-flex justify-content-center'>
              <span className='box-content-title'>
                {t('Total')}
              </span>
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className='box-content-amount'>
                ${moneyFormatter(moneyAmount)}
              </div>
              <div className='box-content-mxn'>
                MXN
              </div>
            </div>
            <div className='d-flex justify-content-center mt-1'>
              <div className='box-content-bitcoin'>
                {numberBtcFormat(bitcoinAmount)}
              </div>
              <div className='box-content-btc'>
                BTC
              </div>
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div>
                <Button
                  onClick={minusAmount}
                  type='red'
                  className="button"
                >
                  {`-$${moneyFormatter(BANKNOTE_VALUE)} MXN`}
                </Button>
              </div>
              <div>
                <Button
                  onClick={plusAmount}
                  type='green'
                  className="button"
                >
                  {`+$${moneyFormatter(BANKNOTE_VALUE)} MXN`}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center mt-56'>
          <div>
            <Button
              disabled={disabledSellButton}
              onClick={onContinue}
              className="btn-custom"
            >
              {t('Selling')}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}
