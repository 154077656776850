import React, {useContext, useEffect, useState} from 'react';
import './TicketPrinterTest.scss';
import {getTicketPrinterConfig} from "../../../../../services/hardware/hardwareConfig";
import {btmLog} from "../../../../../services/localBackendService";
import _ from "lodash";
import Button from "../../../../../components/Button/Button";
import ticket from "../../../../../assets/images/ticket.gif";
import TicketPrinterClient from "../../../../../services/hardware/TicketPrinterClient";
import {AlertContext} from "../../../../../components/Alerts/AlertContext";
import moment from "moment";

const TicketPrinterTest = () => {


    const [config, setConfig] = useState();
    const {addAlert} = useContext(AlertContext);

    useEffect(() => {

        // Get QR reader config
        getTicketPrinterConfig().then(config => {
            setConfig(config?.data);
        }).catch(() => {
            btmLog("No se pudo obtener la configuración de los leds");
        });

    }, []);

    const renderConfig = () => {
        if(!config) return null;
        const configs = [];
        _.forEach(config, (value, key) => {
            return configs.push( <p className='read-result-value' key={key}>{key}: {value+""}</p> );
        })
        return configs;
    }

    const testPrint =async  () => {
        try {
            await (new TicketPrinterClient()).print([
                {type: 'text', value: "Cryptobox", textAlign: 'CT'},
                {type: 'text', value: moment().format('DD/MM/YYYY HH:mm:ss'), textAlign: 'CT'},
                {type: 'text', value: "\nPoema al bitcoin\n\n"},
                {
                    type: 'text', value:
                        "En el firmamento digital, Bitcoin es una estrella,\n" +
                        "Resplandece con promesa en la bóveda más bella.\n" +
                        "Código y matemática en perfecta armonía,\n" +
                        "Apuntan a un mañana lleno de autonomía.\n" +
                        "\n" +
                        "Con cada salto y caída nos enseña a perseverar,\n" +
                        "A enfrentar lo incierto con un optimista titubear.\n" +
                        "Así, Bitcoin se convierte en más que una moneda,\n" +
                        "Es un himno de esperanza que a todo el mundo prenda.\n"
                }
            ]);
            addAlert({type: 'success', message: "Ticket impreso"});
        } catch (e) {
            addAlert({type: 'error', message: "Error al imprimir ticket"});
            btmLog("Error printing test ticket: " + e);
        }
    }



    return (
        <div className={"TicketPrinterTest"}>
            <h1 className='title'>Impresora de tickets</h1>
            <p className='instructions'>Imprime algo para probar la impresora.</p>

            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='read-result'>
                            <p className='read-result-title'>Configuración:</p>
                            {renderConfig()}

                            <div className='text-center'>
                                <Button type={Button.type.primary} className='test-btn' onClick={testPrint}>
                                    Imprimir prueba
                                </Button>
                            </div>


                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <img src={ticket} alt="ticket" />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default TicketPrinterTest;
