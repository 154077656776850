import React, { useCallback, useEffect } from "react";
import "./AdminMenu.scss";
import { logout } from "../../../utils/redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { paths } from "../../../services/routes/appRoutes";
import Layout from "../../../components/Layout/Layout";
import { reboot } from "../../../services/frontCommandSerivce";
import { btmLog } from "../../../services/localBackendService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";

export default function AdminMenu() {

  const operator = useSelector((state) => state.auth.operator);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {sessionId} = useSelector((state) => state.session);

  useEffect(() => {
    btmLog("AdminMenu screen");
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (!operator) {
      navigate("/");
    }
  }, [operator, navigate]);

  const onReboot = () => {
    logSessionEvent(sessionId, sessionEvents.REBOOT, {});
    return reboot({ operatorName: operator?.name});
  };

  return (
    <Layout className="AdminMenu" showFooter={false}>
      <div className="content">
        <div>
          <h3>Hola, {operator?.name}.</h3>
        </div>

        <div className="options-container">
          <div className="options-row">
            <button
              className="admin-option"
              onClick={() => navigate(paths.manageCashbox)}
            >
              Administrar caja
            </button>
            <button
              className="admin-option"
              onClick={() => navigate(paths.banknoteEvents)}
            >
              Eventos de caja
            </button>
          </div>

          <div className="options-row">
            <button
              className="admin-option"
              onClick={() => navigate(paths.register)}
            >
              Registro de BTM
            </button>
            <button className="admin-option" onClick={onReboot}>
              Reiniciar BTM
            </button>
          </div>

          <div className="options-row">
            <button
              className="admin-option"
              onClick={() => navigate(paths.hardwareTest)}
            >
              Test de hardware
            </button>
            <button
              className="admin-option"
              onClick={() => navigate(paths.setTimeZone)}
            >
              Configurar zona horaria
            </button>
          </div>

          <div className="options-row">
            <button
              className="admin-option"
              onClick={() => navigate(paths.drivers)}
            >
              Drivers
            </button>
          </div>
        </div>

        <div className="exit-container">
          <Button onClick={handleLogout} type={Button.type.secondary}>
            {" "}
            Salir{" "}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
