import {getLocalApiUrl} from "./config";
import {io} from "socket.io-client";


export default class LocalWsApi{

    static getInstance(){
        if(!LocalWsApi.instance){
            LocalWsApi.instance = new LocalWsApi();
        }
        return LocalWsApi.instance;
    }

    constructor() {
        this.url = getLocalApiUrl();
        this._io = io(this.url);
    }

    disconnect(){
        console.log('disconnect ws');
        this._io.disconnect();
    }

    async callApi({path, params, fireAndForget=false, timeout=5000}){

        return new Promise((resolve, reject)=>{
            try {
                let callback;

                if (!fireAndForget) {

                    const timeoutId = setTimeout(()=>{
                        reject(new Error('Ws local api call timeout'));
                    }, timeout);

                    callback = (response) => {
                        clearTimeout(timeoutId);
                        resolve(response);
                    }
                }

                const args = [path];
                if (params) {
                    args.push(params);
                }
                if (callback) {
                    args.push(callback);
                }

                this._io.emit(...args);

                if (fireAndForget) {
                    resolve();
                }
            }
            catch (e) {
                reject(e);
            }
        });

    }

    subscribe(eventName, callback) {
        this._io.on(eventName, callback);
    }

    unsubscribe(eventName, callback) {
        this._io.off(eventName, callback);
    }

}
