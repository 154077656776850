import React, {useCallback, useEffect, useState} from 'react'
import "./TransactionVerification.scss"
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import {useNavigate} from 'react-router-dom';
import {paths} from '../../services/routes/appRoutes';
import Checkbox from '../../components/Checkbox/Checkbox';
import {sessionEvents, sessionTypes} from "../../utils/sessionUtils";
import {useSelector} from "react-redux";
import ShadowAlert from "../ShadowAlert/ShadowAlert";
import CircleTimer from "../../components/CircleTimer/CircleTimer";
import {secondsToTimer} from "../../services/constants";
import {btmLog} from "../../services/localBackendService";
import {logSessionEvent} from "../../services/localSessionService";

export default function TransactionVerification() {
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [first, setFirst] = useState(true)
    const {sessionId, kycData, sessionType} = useSelector(state => state.session);
    const [showVerificationSuccessAlert, setShowVerificationSuccessAlert] = useState(false);
    const [nxtPath, setNxtPath] = useState(paths.home);
    const [stateNextPath, setStateNextPath] = useState({});

    const {t} = useTranslation();
    const navigate = useNavigate();

    const onCloseAlert = useCallback(() => {
        setShowVerificationSuccessAlert(false);
        navigate(nxtPath);
    }, [navigate, nxtPath]);

    const handleContinue = useCallback(() => {
        const {kycStatus, termsVersion} = kycData;
        if (termsVersion && kycStatus === 'verified') {
            setShowVerificationSuccessAlert(true);
        } else {
            navigate(nxtPath, {state: stateNextPath});
        }
    }, [navigate, nxtPath, stateNextPath, kycData]);

    const onBack = useCallback((reason) => {
        btmLog("Finishing session with reason: " + reason).catch(console.error);
        logSessionEvent(sessionId, sessionEvents.BACK, {from: "TransactionVerification", to: "Home", reason: "Back button pressed" });
        navigate(paths.home);
    }, [navigate, sessionId]);

    const handleChangeTerms = useCallback((e) => {
        setTermsAccepted(e.target.checked);
    }, []);

    const setNextPath = useCallback((sessionType, amountLimitRemaining, kycStatus, termsVersion) => {
        if (sessionType === sessionTypes.BUY) {
            setNxtPath(paths.scanWallet);
        } else if (sessionType === sessionTypes.SELL) {
            setNxtPath(paths.sell);
        }
        if (amountLimitRemaining === 0) {
            let path = kycStatus === 'verified' ? paths.transactionLimitAlert : paths.transactionLimit;
            setNxtPath(path);
        }
        if (!termsVersion && kycStatus !== 'verified') {
            setNxtPath(function (prevPath) {
                setStateNextPath({nextRoute: prevPath})
                return paths.terms;
            });
        }
    }, [setNxtPath, setStateNextPath]);

    useEffect(() => {
        if (first) {
            setFirst(false);
        }
        const {amountLimitRemaining, kycStatus, termsVersion} = kycData;
        setNextPath(sessionType, amountLimitRemaining, kycStatus, termsVersion);
    }, [first, setNextPath, sessionType, kycData]);

    return (
      <Layout
        showHeader
        showFooter
        backButtonCallback={() => {
            onBack("Back executed by user.");
        }}
        className="TransactionVerification"
      >
          <div className="time-container">
              <CircleTimer
                seconds={secondsToTimer}
                callback={() => {
                    onBack("Scan ticket screen timer.");
                }}
              />
          </div>
          <div className='TransactionVerificationContent'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                  <div>
                      <Title
                        text={t('Verification required')}
                      ></Title>
                  </div>
                  <div className='subtitle mt-2'>
                      {t('In case you do not confirm that the operation is for your benefit, you will need to register in our application.')}
                  </div>
                  <div className='mt-5 d-flex'>
                      <div className='mr-checkbox'>
                          <Checkbox
                            checked={termsAccepted}
                            onChange={handleChangeTerms}
                          ></Checkbox>
                      </div>
                      <div className='verification-text'>
                          {t('I will perform this operation on my own account and for my own benefit.')}
                      </div>
                  </div>
                  <div className='mt-5'>
                      <Button
                        onClick={termsAccepted ? handleContinue : undefined}
                        className={(!termsAccepted) ? 'button-continue button-disabled' : 'button-continue'}
                      >
                          {t('Continue')}
                      </Button>
                  </div>
              </div>
              {
                showVerificationSuccessAlert &&
                <ShadowAlert
                  onClose={onCloseAlert}
                  title={t('Verified identity')}
                  message={t('Verified phone number continuing the transaction')}
                ></ShadowAlert>
              }
          </div>
      </Layout>
    )
}
