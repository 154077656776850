import React from 'react';
import classNames from "classnames";
import {moneyFormatter} from "../../../services/currencyUtils";

const BalanceRow = ({balance}) => {

    const {denomination, count, boxType} = balance;

    return <div className='banknote-row'>
        <span className='denomination-label'>Billetes de ${denomination}{ boxType==="RECYCLING"?"(R)":"" } </span>
        <span className={classNames('count-label', !count && 'empty') }>
            {count || '0'} {!!count && `($${ moneyFormatter(count * denomination) })` }
        </span>
    </div>;
};

export default BalanceRow;
