import moment from "moment";

export const dateFormatter = (date) => {
  if (!date || date === '' ) {
    return '';
  }

  moment.updateLocale('es', {
    monthsShort : [
      "Ene", "Feb", "Mar", "Abr", "May", "Jun",
      "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ]
  });

  const theDate = moment(date);
  return `${theDate.date()} de ${theDate.format('MMM YYYY')}`;
};

export const timeFormatter = (date) => {
  if (!date || date === '') {
    return '';
  }

  const theDate = moment(date);
  return `${theDate.format("hh:mm a").toUpperCase()}`;
};