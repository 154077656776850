
export const moneyFormatter= ( money, currency, fixed = 2 ) =>{
    if( isNaN( Number(money) ) )
        return money;

    return Number(money).toFixed(fixed).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + (currency? ` ${currency}`:'');
};

export const roundNumber = ( num, decimals=2 )=>{
    const scale = 10**decimals;
    return Math.round( num*scale ) / scale;
}
