import React, {useCallback, useEffect} from 'react';
import './TicketPrinterMock.scss';
import MockPanel from "../MockPanel/MockPanel";
import HistoryPanel from "../HistoryPanel/HistoryPanel";
import TicketPrinterClient from "../../../../services/hardware/TicketPrinterClient";
import TicketMock from "./TicketMock/TicketMock";

const TicketPrinterMock = () => {

    const [prints, setPrints] = React.useState([]);

    useEffect(()=>{
        const ticketPrinter = new TicketPrinterClient()
        const printCB = (ticket) => {
            setPrints(prints => [ticket, ...prints]);
        }
        ticketPrinter.on('ticketPrinterMock.onPrint', printCB);

        return () => {
            ticketPrinter.off('ticketPrinterMock.onPrint', printCB);
        }
    }, []);

    const clearHistory = useCallback(() => {
        setPrints([]);
    },[]);

    return (
        <MockPanel className={"TicketPrinterMock"}>
            <h3 className='panel-header'>Ticket printer</h3>
            <hr/>
            <p>No puedes interactuar con la impresora. Solo puedes ver lo que imprime</p>
            <p>Historial <buton class={'clear-history'} onClick={clearHistory}>Limpiar</buton> </p>
            <HistoryPanel>
                {prints.map((print, index) => (
                    <div key={index} className='print'>
                        <TicketMock ticketElements={print}/>
                    </div>
                ))}
            </HistoryPanel>
        </MockPanel>
    );
};

export default TicketPrinterMock;
