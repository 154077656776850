import LocalHttpApi from "./LocalHttpApi";

export const settingKey = {
  APP_VERSION: "APP_VERSION",
  RAYYO_SERVER_URL: "RAYYO_SERVER_URL",
  AUTH_JWT: "AUTH_JWT",
  BTM_ID: "BTM_ID",
  BUY_MULTIPLIER: "BUY_MULTIPLIER",
  WITHDRAW_MULTIPLIER: "WITHDRAW_MULTIPLIER",
  CONTACT_EMAIL: "CONTACT_EMAIL",
  CONTACT_PHONE: "CONTACT_PHONE",
  TERMS: "TERMS",
  TERMS_VERSION: "TERMS_VERSION",
  ACCEPTED_BANKNOTES: "ACCEPTED_BANKNOTES",
  RECYCLING_BANKNOTES: "RECYCLING_BANKNOTES",
  PRIVACY: "PRIVACY",
  HARDWARE_DRIVERS: "HARDWARE_DRIVERS",
};

export const getSetting = async (key) => {
  const api = LocalHttpApi.getInstance();
  const response = await api.callApi({ path: `/settings/${key}` });
  return response?.data?.value;
};

export const saveSetting = async (key, value) => {
  if (!settingKey[key]) throw new Error("Invalid setting key");

  const api = LocalHttpApi.getInstance();
  return await api.callApi({
    path: `/settings/${key}`,
    method: "POST",
    params: { value },
  });
};
