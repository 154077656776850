import React from 'react'
import "./Rate.scss"
import buyIcon from "../../assets/images/buy_icon.svg";
import {formatMoney} from "../../utils/numberUtils";


export default function Rate({text, value}) {
  return (
    <div className='Rate'>
      <div className='d-flex justify-content-between'>
        {
          text && text !== '' &&
          <div className='bitcoin-rate'>
            <div>
              <img src={buyIcon} alt="icon" className='bitcoin-rate-img'/>
              <span className='bitcoin-rate-text'>
                {text}
              </span>
            </div>
            <div className='bitcoin-rate-separator'>&nbsp;</div>
            <div>
              <div className='bitcoin-rate-details'>
                <div className='bitcoin-rate-details-top'>
                  1 BTC
                </div>
                <div className='bitcoins-convert'>
                    ${formatMoney(value)} MXN
                </div>
              </div>
            </div>
          </div>
        }

        {
          (!text || text === '') &&
          <div>&nbsp;</div>
        }
      </div>
    </div>
  )
}
