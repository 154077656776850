import React from 'react'
import "./Footer.scss"

import ToggleLanguage from '../ToggleLanguage/ToggleLanguage';
import Button from "../Button/Button";
import Assistant from '../Assistant/Assistant';
import { useTranslation } from 'react-i18next';

export default function Footer(
    {backButtonCallback, hideButtons, backButtonDisabled}
) {
  const { t } = useTranslation();

  return (
    <div className='Footer'>
      <div className="d-flex justify-content-between">
        {
          hideButtons &&
          <div className="d-flex">&nbsp;</div>
        }
        {
          !hideButtons && !backButtonCallback &&
          <div className='button-language'>
            <ToggleLanguage />
          </div>
        }

        {
          !hideButtons && !!backButtonCallback &&
          <Button
            onClick={backButtonCallback}
            type="secondary"
            disabled={backButtonDisabled}
          >
            {t('Back')}
          </Button>
        }
        <Assistant></Assistant>
      </div>
    </div>
  )
}
