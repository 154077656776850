import React, {useEffect, useState} from 'react'
import "./CircleTimer.scss"

export default function CircleTimer({ seconds, callback }) {

    const [counter, setCounter] = useState(seconds);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(counter =>{
                if(counter <= 0){
                    clearInterval(interval);
                    //setTimeout is used to get out of the render loop and prevent a setState inside another setState
                    setTimeout( callback, 0);
                    return 0;
                }
                return counter - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [callback]);

    const circleLength = 2 * Math.PI * 34;
    const svgDashArray = `${circleLength * counter/seconds} ${circleLength}`;

    return (
        <div className='CircleTimer'>
            <svg width="80" height="80">
                <circle cx="40" cy="40" r={34} stroke="#FCFAFA" strokeWidth="2" fill="none"
                        strokeDasharray={svgDashArray} strokeDashoffset="0" transform="rotate(-90 40 40)"/>
            </svg>
            <div className='counter-text'>
                {counter} S
            </div>
        </div>
    )
}
