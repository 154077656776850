import {useEffect} from 'react'
import "./Success.scss"
import Layout from '../../components/Layout/Layout'
import {useTranslation} from 'react-i18next'
import successImage from '../../assets/images/success-animated.gif'
import {paths} from "../../services/routes/appRoutes";
import {useNavigate} from 'react-router-dom';
import {btmLog} from "../../services/localBackendService";

export default function Success() {

  useEffect(() => {
    btmLog('Success screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      navigate(paths.home)
    }, 5000);
  });

  return (
    <Layout
      showFooter={false}
    >
      <div className='Success'>
        <div className='title'>
          {t('Thanks for your preference!')}
        </div>
        <div className='d-flex justify-content-center'>
          <div className='success-image'>
            <img src={successImage} alt="success" />
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='text-content'>
            {t('Your transaction is in progress!')} <br />
            {t('Once the required quantity has been sent, you can')} <br />
            {t('take 5 to 30 minutes to fully reflect')}
          </div>
        </div>
      </div>
    </Layout>
  )
}
