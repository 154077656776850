import LocalHttpApi from "./LocalHttpApi";
import moment from "moment";

const localHttpApi = LocalHttpApi.getInstance();


export async function createSession( sessionInfo ) {

    const { sessionId, sessionType, ...info } = sessionInfo;

    return localHttpApi.callApi({
        path: "/sessions",
        method: "POST",
        params: { id: sessionId, type: sessionType, sessionInfo: info },
    });
}

export async function updateSession( id, sessionInfo ) {

    return localHttpApi.callApi({
        path: `/sessions/${id}`,
        method: "PUT",
        params: { sessionInfo },
    });
}

/**
 * Add an event to a session events array.
 * @param id
 * @param type
 * @param info
 * @return {*}
 */
export function logSessionEvent( id, type, info ) {

    return localHttpApi.callApi({
        path: `/sessions/${id}/events`,
        method: "POST",
        params: { event: { event: type, info, date: moment().format("YYYY-MM-DD HH:mm:ss") } },
    });
}

/**
 * Set the btmTxId of a session.
 * @param id
 * @param btmTxId
 * @return {*}
 */
export function setSessionBtmTxId( id, btmTxId ) {

    return localHttpApi.callApi({
        path: `/sessions/${id}/btmTxId`,
        method: "PUT",
        params: { btmTxId },
    });
}

/**
 * Finish a session.
 * @param id
 * @return {*}
 */
export function finishSession( id ) {

    return localHttpApi.callApi({
        path: `/sessions/${id}/finish`,
        method: "PUT",
    });
}
