import React from 'react'
import Header from "../Header/Header"
import "./Layout.scss"
import Footer from '../Footer/Footer'
import classNames from "classnames";

export default function Layout(
  {
    children,
    showHeader = true,
    showFooter = true,
    footerIsBack,
    backButtonCallback,
    hideButtons,
    className,
    allowAdminScreen = false,
    backButtonDisabled = false
  }
) {
  return (
    <div className={classNames('Layout', className)}>
      {
        showHeader &&
        <Header allowAdminScreen={allowAdminScreen} />
      }
      <main className='main'>
        {children}
      </main>
      {
        showFooter &&
        <Footer
          isBack={footerIsBack}
          backButtonCallback={backButtonCallback}
          hideButtons={hideButtons}
          backButtonDisabled={backButtonDisabled}
        />
      }
    </div>
  )
}
