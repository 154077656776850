import React, {useState} from 'react';
import './TabsContainer.scss';
import Button from "../Button/Button";
import classNames from "classnames";

/**
 *
 * @param tabs {Array<{label: string, content: JSX.Element}>}
 * @return {JSX.Element}
 * @constructor
 */
const TabsContainer = ({
                           tabs = [],
                       }) => {

    const [activeTab, setActiveTab] = useState(null);

    return (
        <div className={"TabsContainer"}>

            <div className='tab-pane'>
                {tabs.map((tab, index) => (
                    <Button
                        type={Button.type.secondary}
                        key={index}
                        className={classNames('tab', {active: activeTab === index})}
                        onClick={() => setActiveTab(index)}
                    >
                        <label htmlFor={`tab-${index}`}>{tab.title}</label>
                    </Button>
                ))}
            </div>

            <div className='tab-content'>
                {!tabs[activeTab]?.content &&
                    <div className='no-tab'>
                        Selecciona una opción
                    </div>}

                {tabs[activeTab]?.content}

            </div>


        </div>
    );
};

export default TabsContainer;
