import LocalWsApi from "./LocalWsApi";

const controllerPrefix = 'exchangeRate/';

const rateEvents = {
    MXN_RATE_CHANGE: 'exchangeRate.mxnRateChange',
    USD_RATE_CHANGE: 'exchangeRate.usdRateChange',
}

export default class ExchangeRateClient {
    constructor() {
        this.wsApi = LocalWsApi.getInstance();
        this.listeners = [];
        this.handleRateChange = this.handleRateChange.bind(this);
    }

    async getUsdRate(){
        return this.wsApi.callApi({path: controllerPrefix + 'getUsdRate'});
    }

    async getMxnRate(){
        return this.wsApi.callApi({path: controllerPrefix + 'getMxnRate'});
    }

    handleRateChange(rateData){
        this.listeners.forEach( listener => {
            listener(rateData);
        });
    }

    onMxnRateChange( listener ){
        this.listeners.push(listener);
        if(this.listeners.length === 1){
            this.wsApi.subscribe(rateEvents.MXN_RATE_CHANGE, this.handleRateChange);
        }
    }

    offMxnRateChange( listener ){
        this.listeners = this.listeners.filter( l => l !== listener );
        if(this.listeners.length === 0){
            this.wsApi.unsubscribe(rateEvents.USD_RATE_CHANGE, this.handleRateChange);
        }
    }

    removeAllListeners(){
        if (this.listeners.length > 0) {
            console.log("Removing all listeners");
            this.wsApi.unsubscribe(rateEvents.MXN_RATE_CHANGE, this.handleRateChange);
            this.listeners = [];
        }
    }
}
