import React, {useCallback, useContext, useEffect, useState} from 'react'
import "./EnterPhoneNumber.scss"
import NumericPad from '../../components/NumericPad/NumericPad'
import {paths} from '../../services/routes/appRoutes'
import {useNavigate} from 'react-router-dom'
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import {secondsToTimer} from '../../services/constants'
import {useDispatch, useSelector} from "react-redux";
import {clearSession, setPhoneInfo} from "../../utils/redux/sessionSlice";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {sendSms} from "../../services/rayyoClient";
import {asteriskPhoneNumber, generateRandomNumberString} from "../../utils/numberUtils";
import { useTranslation } from 'react-i18next';
import CountryCodes from '../../components/CountryCodes/CountryCodes'
import {countryCodesList} from '../../utils/countryCodesUtils'
import {btmLog} from "../../services/localBackendService";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";
import Alert from "../Alert/Alert";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif";

export default function EnterPhoneNumber() {

    useEffect(() => {
        btmLog('EnterPhoneNumber screen').catch(console.error);
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {addAlert} = useContext(AlertContext);

    const {sessionId} = useSelector(state => state.session);
    const [phone, setPhone] = useState('');
    const [prefix, setPrefix] = useState('52');
    const [focusSelected, setFocusSelected] = useState('phone')
    const [codesList, setCodesList] = useState([])
    const [showAlertInfo, setShowAlertInfo] = useState(false);

    const buttonEnabled = prefix === "52"? (phone.length === 10) : (phone.length > 7 && prefix.length > 0);

    const filterCountryCodes = useCallback((prefixValue) => {
        let codes = [];
        countryCodesList.forEach(element => {
            if (element.countryCode.startsWith(prefixValue)) {
                codes.push(element);
            }
        });
        setCodesList(codes);
    }, []);

    const onPress = useCallback((value) => {
        if (value === 'Delete') {
            if (focusSelected === 'countryCode') {
                setPrefix('');
                setCodesList([]);
            } else {
                setPhone('');
            }
            return;
        }
        if (value === 'Back') {
          if (focusSelected === 'countryCode') {
            if (prefix.length > 0) {
              const newValue = prefix.substring(0, prefix.length - 1);
              setPrefix(newValue);
              if (newValue.length > 0) {
                filterCountryCodes(newValue);
              } else {
                setCodesList([]);
              }
            }
          } else {
            if (phone.length > 0) {
              const newValue = phone.substring(0, phone.length - 1);
              setPhone(newValue);
            }
          }
          return;
        }
        let maxLength = 16;
        if (focusSelected === 'countryCode') {
            maxLength = 4;
        } else if ( prefix === "52" ) {
            maxLength = 10;
        }
        if (phone.length >= maxLength) {
            return;
        }

        if (focusSelected === 'countryCode') {
            const newValue = prefix + value;
            setPrefix(newValue);
            filterCountryCodes(newValue);
        } else {
            const newValue = phone + value;
            setPhone(newValue)
        }

    }, [filterCountryCodes, focusSelected, phone, prefix]);

    const handlePrefixChange = useCallback((codeSelected) => {
      setPrefix(codeSelected.countryCode);
      setCodesList([]);
      setFocusSelected('phone')
    }, []);

    const onBack =( reason ) => {
        logSessionEvent(sessionId, sessionEvents.BACK, {from: "EnterPhoneNumber", to: "Home", reason });
        dispatch(clearSession());
        navigate(paths.home);
    };

    const send = useCallback(async () => {

        if (phone.length < 8) {
            addAlert({message: t('Phone number must have'), type: 'error'});
            return;
        }
        const phoneNumber = "+" + prefix + phone;
        const code = generateRandomNumberString(6);
        try {
            await sendSms({phone: phoneNumber, message: `[Cryptobox] ${t('Your verification code is', {code: code})}`});
            dispatch(setPhoneInfo({  phone: phoneNumber, code}));
        } catch (e) {
            setShowAlertInfo(true);
            return;
        }

        logSessionEvent(sessionId, sessionEvents.SENT_OTP, {phoneNumber: asteriskPhoneNumber(phoneNumber), code });

        navigate(paths.verificationCode);
    }, [phone, prefix, navigate, addAlert, t, dispatch, sessionId]);

    const handleOnFocus = useCallback((type) => {
        setFocusSelected(type);
    }, []);

    return (
        <Layout
            showHeader={true}
            showFooter={true}
            backButtonCallback={()=>onBack("Back button pressed")}
            hideButtons={false}
        >
            <div className='EnterPhoneNumber'>
                <div className='d-flex justify-content-end'>
                    <CircleTimer
                        seconds={secondsToTimer}
                        callback={()=>onBack("Timer finished")}
                    />
                </div>
                <div className='title text-center title-position'>
                    <Title
                        text={t('Enter your phone number')}
                    ></Title>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='gradient-border-mask'>
                        <div className='input-number'>
                            <CountryCodes
                                prefix={prefix}
                                codesList={codesList}
                                onSelected={handlePrefixChange}
                                onFocus={() => handleOnFocus('countryCode')}
                            ></CountryCodes>
                            <div
                                className='input-number-separator'
                            >&nbsp;</div>
                            <div
                                className='input-number-phone'
                                onClick={() => handleOnFocus('phone')}
                            >
                                {phone}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-2 ml-3'>
                    <NumericPad
                        onPress={onPress}
                    ></NumericPad>
                </div>
                <div className='d-flex justify-content-center mt-2 ml-3'>
                    <div>
                        <Button
                            onClick={send}
                            type={Button.type.primary}
                            disabled={!buttonEnabled}
                        >
                            {t('Send')}
                        </Button>
                    </div>
                </div>
            </div>
            {!!showAlertInfo && (
              <Alert
                onClose={() => setShowAlertInfo(false)}
                title={t('Code not sent')}
                message={t('We cannot send the verification code at this time. Please try again later.')}
                textSize={'md'}
                icon={errorAnimatedIcon}
              ></Alert>
            )}
        </Layout>
    )
}
