import LocalHttpApi from "./LocalHttpApi";
import { getSetting, settingKey } from "./appSettings";
import { btmLog } from "./localBackendService";

const localHttpApi = LocalHttpApi.getInstance();

const boxType = {
  CASHBOX: "CASHBOX",
  RECYCLING: "RECYCLING",
};

export async function getBanknotesBalance() {
  return localHttpApi.callApi({
    path: "/banknotes/balance",
    method: "GET",
  });
}

export async function emptyCashbox({ operator }) {
  return localHttpApi.callApi({
    path: "/banknotes/emptyCashbox",
    method: "POST",
    params: { operator },
  });
}

export async function canDispenseAmount({ amount }) {
  const recyclingSetting = await getSetting(settingKey.RECYCLING_BANKNOTES);
  const recyclingNotes = JSON.parse(recyclingSetting);

  const { data: balances } = await getBanknotesBalance();

  let availableAmount = 0;
  for (let i = 0; i < recyclingNotes.length; i++) {
    const note = recyclingNotes[i];
    const balance = balances.find(
      (b) => b.boxType === boxType.RECYCLING && b.denomination === note
    );
    if (balance) {
      availableAmount += balance.count * note;
    }
  }

  const canDispense = availableAmount >= amount;
  if (canDispense) {
    btmLog(
      `Can dispense notes. Available: ${availableAmount} Dispensing: ${amount}`
    ).catch(console.error);
  } else {
    btmLog(
      `Can NOT dispense notes. Available: ${availableAmount} Required: ${amount}`
    ).catch(console.error);
  }

  return availableAmount >= amount;
}

export async function printConsultTicket({ operator }) {
  return localHttpApi.callApi({
    path: "/banknotes/printConsultTicket",
    method: "POST",
    params: { operator },
  });
}

export async function printAdminTransactionTicket({ type, notes, operator }) {
  return localHttpApi.callApi({
    path: "/banknotes/printAdminTransactionTicket",
    method: "POST",
    params: {
      type,
      notes,
      operator,
    },
  });
}

export function saveManualCashboxRefill(boxesData) {
  return localHttpApi.callApi({
    path: "/banknotes/saveManualCashboxRefill",
    method: "POST",
    params: boxesData,
  });
}
