import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Rate from '../../components/Rate/Rate'
import Wallet from '../../components/Wallet/Wallet'
import "./BuyBitcoinConfirm.scss"
import { paths } from '../../services/routes/appRoutes'
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import InstructionsBox from '../../components/InstructionsBox/InstructionsBox'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import {useDispatch, useSelector} from "react-redux";
import BanknoteRecyclerClient, {banknoteRecyclerEvents} from "../../services/hardware/BanknoteRecylcerClient";
import {formatMoney, formatBankNotes} from "../../utils/numberUtils";
import {numberBtcFormat} from "../../utils/btcUtils";
import {sendReceipt, withdrawBtc} from "../../services/rayyoClient";
import {setInsertedBanknotes} from "../../utils/redux/sessionSlice";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {btmLog} from "../../services/localBackendService";
import { useTranslation } from 'react-i18next';
import TicketPrinterClient from "../../services/hardware/TicketPrinterClient";
import CurrentLimit from '../../components/CurrentLimit/CurrentLimit'
import {blinkLed, ledId, turnOffLed} from "../../services/hardware/ledsService";
import { createTransaction } from '../../services/localTransactionService'
import { transactionTypes } from '../../utils/transactionUtils'
import {stopRecording} from "../../services/localSecurityCameraService";
import Alert from "../Alert/Alert";
import errorAnimatedIcon from "../../assets/images/info-red-animated.gif";
import {logSessionEvent, setSessionBtmTxId} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

export default function BuyBitcoinConfirm() {

  useEffect(() => {
    btmLog('BuyBitcoinConfirm screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Context
  const {addAlert} = useContext(AlertContext);

  // Constants
  const session = useSelector((state) => state.session);
  const {sessionId, marketPrice, buyPrice, userWallet, phoneInfo, kycData} = session;
  const currentLimit = kycData.amountLimitRemaining;
  const isKYC = kycData.kycStatus === 'verified';
  const secondsToTimer = 20;

  // States
  const [addedBanknotes, setAddedBanknotes] = useState([]);
  const [allowedNotes, setAllowedNotes] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [timerResetCount, setTimerResetCount] = useState(0);
  const [timerEnabled, setTimerEnabled] = useState(false);
  const [showAlertInfo, setShowAlertInfo] = useState(null);

  // Banknote Recycler client
  const recycler = useMemo(() => new BanknoteRecyclerClient(), []);

  const onTimeout = useCallback(() => {
    logSessionEvent(sessionId, sessionEvents.BACK, {from: "BuyBitcoinConfirm", to: "Home", reason: "Timer finished" });
    navigate(paths.home);
  },[navigate, sessionId]);

  const resetTimer = useCallback(() => {
    setTimerResetCount(old => old + 1);
  }, []);

  useEffect(() => {
    let localAddedBankNotes = [];
    let allowedBanknotes;
    blinkLed(ledId.BANKNOTE_ACCEPTOR).catch(console.error);

    const allowedBanknotesForAmount = payed => {
      const mainBanknote = 500;

      if (allowedBanknotes == null || allowedBanknotes.length === 0) {
        return [];
      }
      if (currentLimit >= mainBanknote && payed === 0) {
        return [mainBanknote];
      }
      if (currentLimit < mainBanknote && payed === 0) {
        return allowedBanknotes.filter((banknote) => banknote <= currentLimit && banknote < mainBanknote);
      }

      return allowedBanknotes.filter((banknote) => banknote <= (currentLimit - payed));
    };

    const onReturn = () => {
      if (localAddedBankNotes.length === 0) {
        setShowAlertInfo({
          title: t('Insert another bill'),
          message: t('Remember that the first bill you insert must be a 500 MXN bill to continue with the transaction.'),
        });
      }
    };

    const onEscrow = ({denomination: amount}) => {

      logSessionEvent(sessionId, sessionEvents.INSERTED_NOTE, {value: amount });

      setAddedBanknotes(old => {
        const all = [...old, amount];
        localAddedBankNotes = all
        const sum = all.reduce((partialSum, a) => partialSum + a, 0);
        setButtonEnabled(true);
        // Reset allowed banknotes to prevent inserting more than the limit
        recycler.startEscrow(allowedBanknotesForAmount(sum)).catch(console.error);
        btmLog(`Banknotes inserted in session: ${JSON.stringify(all)}`).catch(console.error);
        return all;
      });
    };

    const onFailedNote = ( value ) => {
      logSessionEvent(sessionId, sessionEvents.REJECTED_NOTE, { value });
    }

    recycler.on(banknoteRecyclerEvents.RETURNED, onFailedNote)
    recycler.on(banknoteRecyclerEvents.REJECTED, onFailedNote)

    const setAllowedBanknotes = settingsAcceptedBanknotes => {
      allowedBanknotes = settingsAcceptedBanknotes;
      setAllowedNotes(settingsAcceptedBanknotes);
      recycler.on(banknoteRecyclerEvents.ESCROW, onEscrow);
      recycler.on(banknoteRecyclerEvents.RETURNED, onReturn);
      recycler.startEscrow(allowedBanknotesForAmount(0)).catch(console.error);
    };

    recycler.getAcceptedBanknotes().then(setAllowedBanknotes)

    return () => {
      turnOffLed(ledId.BANKNOTE_ACCEPTOR).catch(console.error);
      recycler?.off(banknoteRecyclerEvents.ESCROW, onEscrow);
      recycler?.off(banknoteRecyclerEvents.RETURNED, onReturn);
      recycler?.stopEscrow();
      recycler?.off(banknoteRecyclerEvents.RETURNED, onFailedNote)
      recycler?.off(banknoteRecyclerEvents.REJECTED, onFailedNote)
    }
  }, [recycler, currentLimit, setAllowedNotes, t, sessionId]);

  const onBack = useCallback(() => {
    navigate(paths.buy);
  }, [navigate])

  const onBuy = async () => {
    // Stop escrow and disable button
    recycler.stopEscrow().catch(err => { btmLog(`Error on stop escrow: ${err}`).catch(console.error); });
    setButtonEnabled(false);

    let mxnAmount = addedBanknotes.reduce((acc, curr) => acc + curr, 0);//Add all banknotes inserted
    let btcAmount = mxnAmount / buyPrice;

    mxnAmount = Math.round(mxnAmount * 100); // Convert to cents
    btcAmount = Math.round(btcAmount * 100_000_000); // Convert to satoshis

    try {
      await stopRecording({fireAndForget: false})
        .catch(error => btmLog(error.toString()).catch(console.error));

      const {data} = await withdrawBtc({
        sessionId: sessionId,
        address: userWallet,
        phone: phoneInfo.phone,
        mxnAmount,
        btcAmount,
        marketPrice,
        buyPrice,
      });

      await setSessionBtmTxId(sessionId, data.btmTxId);

      await sendReceipt({
        phone: phoneInfo.phone,
        btmTxId: data.btmTxId,
        message: t("ReceiptMessage")
      }).catch(error => btmLog(error.toString()).catch(console.error));

      (new TicketPrinterClient()).printBuyBtcTicket({
        address: userWallet,
        phone: phoneInfo.phone,
        mxnAmount,
        btcAmount,
        buyPrice,
        id: sessionId
      }).catch(e => {
        console.error(e)
        addAlert({type: 'error', message: t('An error occurred while printing ticket')});
      });

      await createTransaction(
        {
          amount: mxnAmount / 100,
          type: transactionTypes.SELL,
          username: 'n/a',
          notes: 0,
          txId: sessionId,
        }
      );

      logSessionEvent(sessionId, sessionEvents.BUY_SUCCESS, {mxnAmount, btcAmount, marketPrice, buyPrice, btmTxId: data.btmTxId});

      dispatch(setInsertedBanknotes(addedBanknotes));
      if (isKYC) {
        navigate(paths.takeYourTicket);
      } else {
        navigate(paths.increaseLimit, {state: {nextRoute: paths.takeYourTicket, showBackButton: false}});
      }

    } catch (e) {
      // Enable button
      setButtonEnabled(true);

      // Enable and reset timer
      setTimerEnabled(true);
      resetTimer();

      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "BuyBitcoinConfirm", critical: false, error: e?.message || e });

      // show & log error
      setShowAlertInfo({
        title: t('Transaction error'),
        message: t('There was a problem trying to process the transaction. Please try again.'),
      });
      console.error(e);
    }
  };

  const moneyAdded = addedBanknotes.reduce((acc, curr) => acc + curr, 0);

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={onBack}
      hideButtons={!!addedBanknotes.length}
      backButtonDisabled={buttonEnabled}
    >
      <div className='BuyBitcoinConfirm'>
        <div className='title text-center'>
          <Title
            text={t('Buy Bitcoin')}
          ></Title>
        </div>
        <div className='d-flex justify-content-between'>
          <Rate
            text={t('Buy')}
            value={buyPrice}
          />
          {
            timerEnabled > 0 &&
            <CircleTimer
              seconds={secondsToTimer}
              key={timerResetCount}
              callback={onTimeout}
            />
          }
        </div>
        <div className='d-flex justify-content-between margin-custom'>
          <div className='d-flex flex-column'>
            <div>
              <Wallet address={userWallet} />
            </div>
            <div className='d-flex justify-content-center'>
              <div className='warning-limit'>
                <CurrentLimit
                  showIcon={true}
                  showButton={false}
                >
                  {
                    !isKYC &&
                    t('Current limit is: ', {amount: '$' + formatMoney(currentLimit)})
                  }
                  {
                    isKYC &&
                    t('Current transaction limit is: ', {amount: '$' + formatMoney(currentLimit)})
                  }
                </CurrentLimit>
              </div>
            </div>
            <div className='box-amounts mt-4'>
              <div className='d-flex justify-content-center'>
                <span className='box-amounts-title'>
                  {t('Total inserted')}
                </span>
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <div className='box-amounts-amount'>
                  ${formatMoney(moneyAdded)}
                </div>
                <div className='box-amounts-mxn'>
                  MXN
                </div>
              </div>
              <div className='d-flex justify-content-center mt-1'>
                <div className='box-amounts-bitcoin'>
                  {numberBtcFormat(moneyAdded / buyPrice)}
                </div>
                <div className='box-amounts-btc'>
                  BTC
                </div>
              </div>
          </div>
          <div className='label-info'>
            {t('Only mxn banknotes')}
          </div>
        </div>
        <div>
          <InstructionsBox
            title={t('Banknotes')}
            list={[
              <strong>{t('The first bill entered must be $500')}</strong>,
              t('Bills Accepted: ') + formatBankNotes(allowedNotes),
              t('Insert the bills one by one'),
              t('This BTM does not accept torn, stapled, bent or mistreated bills'),
              t('This BTM does not accept coins'),
              t('This BTM only accepts Mexican pesos')
            ]}
            boxClass="box-instructions"
            titleClass="conditions-title"
          ></InstructionsBox>
        </div>
        </div>
        <div className='d-flex justify-content-center mt-4'>
          <div>
            <Button
              onClick={onBuy}
              disabled={!buttonEnabled}
              className="button-buy"
            >
              {t('Purchase')}
            </Button>
          </div>
        </div>
      </div>
      {!!showAlertInfo && (
        <Alert
          onClose={() => setShowAlertInfo(null)}
          title={showAlertInfo.title || t('Insert another bill')}
          message={showAlertInfo.message || t('Remember that the first bill you insert must be a 500 MXN bill to continue with the transaction.')}
          icon={errorAnimatedIcon}
          textSize={'md'}
        ></Alert>
      )}
    </Layout>
  )
}
