import React from 'react';
import './SecurityCamera.scss';
import MockPanel from "../MockPanel/MockPanel";
import HistoryPanel from "../HistoryPanel/HistoryPanel";

const SecurityCamera = () => {



    return (
        <MockPanel className={"SecurityCamera"}>
            <h3>Video camera</h3>
            <hr/>
            <p>Usar la cámara del explorador sería un desarrollo complicado, ya que el cajero opera su cámara desde del
                sistema operativo. Por el momento solo podrás ver los eventos de inicio y fin</p>

            <p>Historial</p>
            <HistoryPanel>

            </HistoryPanel>
        </MockPanel>
    );
};

export default SecurityCamera;
