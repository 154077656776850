import React, { useContext } from 'react';
import classNames from "classnames";
import './Alerts.scss';
import { AlertContext } from './AlertContext';

const alertType = {
    success: 'success',
    error: 'error',
    warning: 'warning',
}

const Alerts = () => {

    const { alerts } = useContext(AlertContext);

    return (
        <div className='Alerts'>
            {alerts.map((alert) => (

                <div key={alert.id} className={classNames('alert', alert.type)}>
                    {alert.message}
                </div>

            ))}
        </div>
    );
};

Alerts.alertType = alertType;

export default Alerts;
