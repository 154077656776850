
export const getLocalApiUrl = () => {

    if( localStorage.apiUrl ) {
        return localStorage.apiUrl;
    }

    const { hostname } = window.location;
    return `http://${hostname}:9000`;
}

