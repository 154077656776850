import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './sessionSlice';
import authReducer from './authSlice'

export default configureStore({
    reducer: {
        session: sessionReducer,
        auth: authReducer
    },
});
