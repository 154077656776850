

export const sessionTypes = {
    BUY: 'buy',
    SELL: 'sell',
    REDEEM: 'redeem',
    ADMIN: 'admin',
}

export const sessionEvents = {
    START_SESSION: 'start-session',
    SENT_OTP: 'sent-otp',
    OTP_TYPED: 'otp-typed',
    QR_READ: 'qr-read',
    INSERTED_NOTE: 'inserted-note',
    REJECTED_NOTE: 'rejected-note',
    BUY_SUCCESS: 'buy-success',
    SELL_SUCCESS: 'sell-success',
    REDEEM_VALIDATION: 'redeem-validation',
    REDEEM_SUCCESS: 'redeem-success',

    ADMIN_LOGIN: 'admin-login',
    ADMIN_NAV: 'admin-nav',
    EMPTY_CASHBOX: 'empty-cashbox',
    REFILL_CASHBOX: 'refill-cashbox',
    MANUAL_REFILL: 'manual-refill',
    PRINT_BALANCE: 'print-balance',
    REBOOT: 'reboot',

    ERROR: 'error',
    BACK: 'back',
}
