/**
 * RayyoClient is a wrapper for the Rayyo API through the local nodejs server up to the remote Rayyo API.
 */
import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function registerBTM(key) {
    return localHttpApi.callApi({
        path: "/rayyo/register",
        method: "POST",
        params: { key },
    });
}

export async function getBTMConfig() {
    return localHttpApi.callApi({
        path: "/rayyo/getConfig",
        method: "GET",
    });
}

export async function sendSms( { phone, message } ) {
    return localHttpApi.callApi({
        path: "/rayyo/sendSms",
        method: "POST",
        params: { phone, message },
    });
}


export async function withdrawBtc( { sessionId, address, phone, mxnAmount, btcAmount, marketPrice, buyPrice } ) {
    return localHttpApi.callApi({
        path: "/rayyo/withdrawBtc",
        method: "POST",
        params: { sessionId, address, phone, mxnAmount, btcAmount, marketPrice, buyPrice },
    });
}

export async function createReceiveTransaction( { sessionId, phone, mxnAmount, btcAmount, marketPrice, sellPrice } ) {
    return localHttpApi.callApi({
        path: "/rayyo/receiveAddress",
        method: "POST",
        params: { sessionId, phone, mxnAmount, btcAmount, marketPrice, sellPrice },
    });
}

export async function validateReception( { address } ) {
    return localHttpApi.callApi({
        path: "/rayyo/validateReception",
        method: "POST",
        params: { address },
    });
}

export async function confirmWithdrawal( { btmTxId, sessionId } ) {
    return localHttpApi.callApi({
        path: "/rayyo/confirmWithdrawal",
        method: "POST",
        params: { btmTxId, sessionId },
    });
}

export async function sendReceipt( { phone, btmTxId, message, type = 'buy' } ) {
    return localHttpApi.callApi({
        path: "/rayyo/sendReceipt",
        method: "POST",
        params: { phone, btmTxId, message, type },
    });
}

export async function getKycData( { phone } ) {
    return localHttpApi.callApi({
        path: "/rayyo/kycData/" + phone,
        method: "GET"
    });
}


