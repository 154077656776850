import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './locales/es/translations.js';
import en from './locales/en/translations.js';

export const enabledLanguages = [
    { value: 'es', label: 'Spanish' },
    { value: 'en', label: 'English' },
];

i18n.languages = enabledLanguages.map(l => l.value);

i18n
    .use(initReactI18next)
    .init({
    fallbackLng: i18n.languages[0],
    resources: {
        es: {translations: es},
        en: {translations: en},
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

export const changeLanguage = ( newLanguage )=>{
    return i18n.changeLanguage(newLanguage);
}

export default i18n;
