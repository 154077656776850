import LocalWsApi from "./LocalWsApi";

const checkBtmStatusEvents = {
    STATUS_CHANGE: 'checkStatus.statusChange',
}

const outOfServiceStatus = {
    manualOutOfService: 100,
    internetOffline: 101,
    remoteServerOffline: 102,
    localServerOffline: 103,
    recyclerOffline: 104,
    ledOffline: 105,
    btmUnlinked: 106,
    manualDisabled: 107,
};

export default class CheckBtmStatusService {

    static getInstance(){
        if(!CheckBtmStatusService.instance){
            CheckBtmStatusService.instance = new CheckBtmStatusService();
        }
        return CheckBtmStatusService.instance;
    }

    constructor() {
        // Singleton
        if (CheckBtmStatusService.instance) {
            return CheckBtmStatusService.instance;
        }
        CheckBtmStatusService.instance = this;

        this.wsApi = LocalWsApi.getInstance();
        this.listeners = [];

        // The flags found in outOfServiceStatus are the ones that take the btm out of service
        this._status = {
            // Connectivity issue
            remoteServerOffline: false, // Rayyo API is down
            internetOffline: false, // Internet is down
            localServerOffline: false, // Local btm server
            btmUnlinked: false, // BTM is not linked to the server

            //Hardware issues
            ledOffline: false, // LED device is offline
            recyclerOffline: false, // RECYCLER device is offline

            // Server-set status flags
            mainWalletOutOfBtc: false,
            manualOutOfService: false,
            manualDisabled: false,
            disableBuy: false,
            disableSell: false,
            disableRedeem: false,

        };

        this.wsApi.subscribe('connect', ()=>{
            this.handleWSConnectionChange(true);
        });

        this.wsApi.subscribe('disconnect', ()=>{
            this.handleWSConnectionChange(false);
        });
    }

    handleWSConnectionChange = async (online)=>{
        this._status = {...this._status, localServerOffline: !online};
        if(online) {
            await this.fetchStatus();
        }
        this.broadcastStatusChange();
    }

    async fetchStatus() {
        try {
            const status = await this.wsApi.callApi({path: 'btmStatus/status'});
            this.handleStatusChange(status);
        } catch (e) {
            console.error('Error fetching btm status', e);
            this.handleStatusChange({localServerOffline: true})
        }
    }

    getStatus() {
        return this._status;
    }

    /**
     * Get if the btm should be out of service.
     * It returns the first out of service status found.
     * @return {{code: number, outOfService: boolean}}
     */
    getOutOfService() {

        let outOfService = false;
        let code = 0;

        for(const key in outOfServiceStatus){
            if(this._status[key]){
                outOfService = true;
                code = outOfServiceStatus[key];
                break;
            }
        }
        return {outOfService, code};
    }

    handleStatusChange = (status)=>{
        // compare with current status and broadcast only if something changed
        const statusKeys = Object.keys(status);
        let changed = false;
        for (const key of statusKeys) {
            if (this._status[key] !== status[key]) {
                this._status[key] = status[key];
                changed = true;
            }
        }
        if(changed){
            this._status = {...this._status};
            this.broadcastStatusChange();
        }
    }

    broadcastStatusChange() {
        this.listeners.forEach(listener => {
            listener(this.getStatus());
        });
    }

    onStatusChange( listener ) {
        this.listeners.push(listener);
        if(this.listeners.length === 1){
            this.wsApi.subscribe(checkBtmStatusEvents.STATUS_CHANGE, this.handleStatusChange);
        }
    }

    removeListener(listener){
        this.listeners = this.listeners.filter(l => l !== listener);
        if(this.listeners.length === 0){
            this.wsApi.unsubscribe(checkBtmStatusEvents.STATUS_CHANGE, this.handleOutOfServiceChange);
        }
    }

}
