import React, {useContext, useEffect, useState} from 'react';
import './RefillCashbox.scss';
import Layout from "../../../components/Layout/Layout";
import {useSelector} from "react-redux";
import {getSetting, settingKey} from "../../../services/appSettings";
import Button from "../../../components/Button/Button";
import {paths} from "../../../services/routes/appRoutes";
import {useNavigate} from "react-router-dom";
import {AlertContext} from "../../../components/Alerts/AlertContext";
import BanknoteRecyclerClient, {banknoteRecyclerEvents} from "../../../services/hardware/BanknoteRecylcerClient";
import {btmLog} from "../../../services/localBackendService";
import {moneyFormatter} from "../../../services/currencyUtils";
import { createTransaction } from '../../../services/localTransactionService';
import { transactionTypes } from '../../../utils/transactionUtils';
import {printAdminTransactionTicket} from "../../../services/frontBanknoteService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";

const RefillCashbox = () => {

    const {sessionId} = useSelector((state) => state.session);
    const operator = useSelector(state => state.auth.operator);
    const navigate = useNavigate();
    const {addAlert} = useContext(AlertContext);

    const [acceptedBanknotes, setAcceptedBanknotes] = useState([]);
    const [addedBanknotes, setAddedBanknotes] = useState([]);
    const [banknotes, setBanknotes] = useState({});
    const [finishDisabled, setFinishDisabled] = useState(false);

    useEffect(() => {
        btmLog('RefillCashbox screen');
        logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "RefillCashbox" });
    }, [sessionId]);



    //Load accepted banknotes
    useEffect(()=>{
        let mounted = true;
        (async () => {
            try {
                const setting = await getSetting(settingKey.RECYCLING_BANKNOTES)
                if (mounted && setting)
                    setAcceptedBanknotes(JSON.parse(setting));
            } catch (e) {
                addAlert({message: 'Error al obtener billetes aceptados. '+ e.message, type:'error'})
            }
        })();

        return () => {
            mounted = false
        }
    },[setAcceptedBanknotes, addAlert]);

    //Start accepting banknotes
    useEffect(()=>{

        const recycler = new BanknoteRecyclerClient();
        recycler.startEscrowForARefill().catch(e=>{
            addAlert({message: 'Error al iniciar reciclador. '+ e.message, type:'error'});
            btmLog("RefillCashbox Component Error - startEscrowForARefill - "+e.message);
        });

        recycler.on( banknoteRecyclerEvents.ESCROW, ({denomination}) => {
            setBanknotes(oldBanknotes => {
                const updatedBanknotes = {
                    ...oldBanknotes,
                    [denomination]: (oldBanknotes[denomination] || 0) + 1,
                };
                btmLog(`Banknotes inserted by admin: ${JSON.stringify(updatedBanknotes)}`);
                return updatedBanknotes;
            });

            setAddedBanknotes(old =>{
                const all = [...old, denomination];
                btmLog(`Banknotes inserted by admin: ${JSON.stringify(all)}`);
                return all;
            });
        });

        return () => {
            recycler?.stopEscrow();
            recycler.removeAllListeners();
        }

    },[addAlert]);


    const handleFinish = async () => {

        btmLog("[RefillCashbox] Finishing refill cashbox.");
        // Disable button to prevent double click
        setFinishDisabled(true);

        const amount = addedBanknotes.reduce((a,b) => a + b, 0);
        const type= transactionTypes.ADMIN_INSERT;
        const notes = addedBanknotes.length;
        await createTransaction({
            amount,
            type,
            notes,
            username: operator.username,
            txId: sessionId,
        });

        logSessionEvent(sessionId, sessionEvents.REFILL_CASHBOX, { notes: addedBanknotes, total: amount });

        if (amount > 0) {
            try {
                // Wait for 3 seconds before printing to make sure the last event from the recycler was added to the db balance
                await new Promise(resolve => setTimeout(resolve, 3000));

                await printAdminTransactionTicket({type, notes: banknotes, operator})
                addAlert({message: 'Impresión de ticket realizada correctamente', type: 'success'})
            } catch (e) {
                addAlert({message: 'Error al imprimir ticket de dotación. ' + e.message, type: 'error'})
                const errStr = 'Error printing admin ticket: ' + e?.message || e;
                logSessionEvent(sessionId, sessionEvents.ERROR, {step: "RefillCashbox", critical: false, error: errStr });
                btmLog("ManageCashbox - Error in printAdminTransactionTicket: " + e);
            }
        }
        navigate(paths.manageCashbox);
    }

    const totalAdded = addedBanknotes.reduce((a,b)=>a+b,0);

    return (
        <Layout className={"RefillCashbox"} showFooter={false}>

            <div className='content'>
                <div>
                    <h3>
                        {operator?.name}, puedes ingresar billetes de {acceptedBanknotes.join(', ')}.
                    </h3>
                </div>

                <div className={"added-amount"}>
                    <div className='amount'>${moneyFormatter(totalAdded)}</div>
                    <div className='label'>Total ingresado</div>
                </div>

                <div className='button-container'>
                    <Button onClick={handleFinish} className="button-continue" disabled={finishDisabled}>
                        Finalizar
                    </Button>
                </div>

            </div>

        </Layout>
    );
};

export default RefillCashbox;
