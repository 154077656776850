import React, { useContext, useEffect, useState } from "react";

import "./ManualRefillCashbox.scss";
import Layout from "../../../components/Layout/Layout";
import Button from "../../../components/Button/Button";
import { paths } from "../../../services/routes/appRoutes";
import { useNavigate } from "react-router-dom";
import { btmLog } from "../../../services/localBackendService";
import TextInput from "../../../components/TextInput/TextInput";

import { AlertContext } from "../../../components/Alerts/AlertContext";
import { saveManualCashboxRefill } from "../../../services/frontBanknoteService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";
import {useSelector} from "react-redux";

const ManualRefillCashbox = () => {
  const { addAlert } = useContext(AlertContext);

  const {sessionId} = useSelector((state) => state.session);
  const [boxValues, setBoxValues] = useState([
    { box: 1, denomination: 500, value: 0 },
    { box: 2, denomination: 500, value: 0 },
  ]);

  useEffect(() => {
    btmLog("RefillCashbox screen");
    logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "ManualRefillCashbox" });
  }, [sessionId]);

  const navigate = useNavigate();

  const handleBoxChange = (boxNumber, event) => {
    setBoxValues((prevBoxValues) =>
      prevBoxValues.map((box) =>
        box.box === boxNumber ? { ...box, value: event } : box
      )
    );
  };

  const handleFinish = async () => {
    try {
      const parsedBoxValues = boxValues.map((item) => {
        if (isNaN(parseInt(item.value))) {
          item.value = 0;
        }
        return { ...item, value: parseInt(item.value) };
      });
      saveManualCashboxRefill(parsedBoxValues);

      logSessionEvent(sessionId, sessionEvents.MANUAL_REFILL, { boxValues: parsedBoxValues });

      addAlert({
        type: "success",
        message: "Balances guardados correctamente",
      });
    } catch (e) {
      addAlert({
        type: "error",
        message: "Error al guardar" + e.message,
      });
      const errStr = "ManualRefillCashbox - fail to finish. " + e.message || "Unknown error";
      logSessionEvent(sessionId, sessionEvents.ERROR, {step: "ManageCashbox", critical: false, error: errStr });
      btmLog(errStr);

    } finally {
      navigate(paths.manageCashbox);
    }
  };

  return (
    <Layout className={"ManualRefillCashbox"} showFooter={false}>
      <div className="content">
        <h1>Refill manual para dispensadora de billetes SMD</h1>
        {boxValues.map((box) => (
          <div
            className="d-flex align-items-center justify-content-around refill-box"
            key={box.box}
          >
            <h2>{`Caja ${box.box} - Denominación $${box.denomination}`}</h2>

            <TextInput
              type="text"
              id={`box${box.box}Amount`}
              onChange={(e) => handleBoxChange(box.box, e)}
              value={box.value}
            />
          </div>
        ))}

        <div className="button-container">
          <Button onClick={handleFinish} className="button-continue">
            Guardar
          </Button>
        </div>

        <Button
          type={Button.type.secondary}
          className="return-button"
          onClick={() => navigate(paths.manageCashbox)}
        >
          Regresar
        </Button>
      </div>
    </Layout>
  );
};

export default ManualRefillCashbox;
