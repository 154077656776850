import LocalHttpApi from "../LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function getQrReaderConfig() {
    return localHttpApi.callApi({path: "/hardware/qr_reader"});
}

export async function getLedsConfig() {
    return localHttpApi.callApi({path: "/hardware/leds"});
}

export async function getTicketPrinterConfig() {
    return localHttpApi.callApi({path: "/hardware/ticket_printer"});
}

