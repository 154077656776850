import React, {useCallback, useContext, useEffect, useState} from 'react';
import './SetTimeZone.scss';
import Layout from "../../../components/Layout/Layout";
import Button from "../../../components/Button/Button";
import {paths} from "../../../services/routes/appRoutes";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as timeZoneService from "../../../services/localTimeZoneService";
import TimeZoneRow from "./TimeZoneRow";
import Alerts from "../../../components/Alerts/Alerts";
import {AlertContext} from "../../../components/Alerts/AlertContext";
import {btmLog} from "../../../services/localBackendService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";

const SetTimeZone = () => {
    const operator = useSelector(state => state.auth.operator);
    const navigate = useNavigate();
    const {addAlert} = useContext(AlertContext);
    const {sessionId} = useSelector((state) => state.session);

    // List by system
    const [systemListZones, setSystemListZones] = useState([]);
    const [systemListTimeZones, setSystemListTimeZones] = useState([]);
    const [systemTimeZone, setSystemTimeZone] = useState('');

    // Selected by user
    const [zone, setZone] = useState('Mexico');
    const [timeZone, setTimeZone] = useState('');

    useEffect(() => {
        btmLog('SetTimeZone screen');
        logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "SetTimeZone" });
    }, [sessionId]);

    useEffect(() => {
        timeZoneService.getZones().then(response => setSystemListZones(response.data));
        timeZoneService.getTimeZonesByZone({}).then(response => setSystemListTimeZones(response.data));
        timeZoneService.getCurrentTimeZone().then(response => setSystemTimeZone(response.data));
    }, [setSystemListZones, setSystemListTimeZones, setSystemTimeZone]);

    /**
     * Retrieves a list of time zones by zone.
     *
     * @param {string} zone - The zone for which to retrieve the time zones.
     *
     * @throws {Error} If an error occurs during the retrieval process.
     */
    const getTimeZonesByZone = useCallback(async zone => {
        try {
            setZone(zone);
            const response = await timeZoneService.getTimeZonesByZone({zone});
            setSystemListTimeZones(response.data);
        } catch (e) {
            addAlert({message: 'Error. ' + (e.message || ''), type: Alerts.alertType.error});
        }
    }, [setSystemListTimeZones, setZone, addAlert]);

    /**
     * A callback function used to update the system time zone.
     *
     * @async
     * @function updateSystemTimeZone
     * @returns {Promise<void>} A promise that resolves when the system time zone is updated successfully.
     * @throws {Error} if an error occurs while updating the system time zone.
     */
    const updateSystemTimeZone = useCallback(async () => {
        try {
            await timeZoneService.setCurrentTimeZone({timeZone: timeZone});
            addAlert({message: `Se actualizó la zona horaria ${timeZone}`, type: Alerts.alertType.success})
            setSystemTimeZone(timeZone);
        } catch (e) {
            addAlert({message: 'Error. ' + (e.message || ''), type: Alerts.alertType.error});
        }
    }, [addAlert, setSystemTimeZone, timeZone]);

    return (
        <Layout className={"SetTimeZone"} showFooter={false}>
            <div className='content'>
                <div>
                    <h3>
                        Hola {operator?.name}, la zona horaria del sistema es {systemTimeZone}.
                    </h3>
                </div>
                <div className="content-tables">
                    <div className={'zones-table'}>
                        {
                            systemListZones.map((tzone, i) =>
                                <TimeZoneRow
                                    isSelected={tzone === zone}
                                    key={i}
                                    zone={tzone}
                                    onClick={getTimeZonesByZone}
                                />
                            )
                        }
                    </div>

                    <div className={'zones-table'}>
                        {
                            systemListTimeZones.map((tzone, i) =>
                                <TimeZoneRow
                                    isSelected={tzone === timeZone}
                                    key={i}
                                    zone={tzone}
                                    onClick={setTimeZone}
                                />
                            )
                        }
                    </div>
                </div>

                <Button className="button-continue" onClick={updateSystemTimeZone}>
                    Cambiar zona horaria
                </Button>

                <Button type={Button.type.secondary} className='return-button'
                        onClick={() => navigate(paths.adminMenu)}>
                    Regresar
                </Button>
            </div>
        </Layout>
    );
};

export default SetTimeZone;
