import React, {useCallback, useEffect, useState} from 'react';
import './Boot.scss';
import _ from 'lodash';
import {checkAuth, checkHardware} from "../../services/bootService";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {getBTMConfig} from "../../services/rayyoClient";
import {btmLog} from "../../services/localBackendService";
import OutOfService from "../OutOfService/OutOfService";

const Boot = () => {

    useEffect(() => {
        btmLog('Boot screen').catch(console.error);
    }, []);

    /**
     * Checks [ { key:'string', state:'loading'|'success'|'fail', text:'Description'  } ]
     */
    const [checks, setChecks] = useState([]);
    const navigate = useNavigate();

    const setCheck = useCallback((check) => {
        setChecks( checks =>{
            const index = _.findIndex(checks, {title: check.title});
            if(index === -1){
                return [...checks, check];
            }
            else{
                return [...checks.slice(0, index), check, ...checks.slice(index + 1)];
            }
        })
    },[setChecks]);

    useEffect(() => {

        let timeout = 0;

        const checkAll = async () => {
            const tests = [
                {title: "Registro de BTM", test: checkAuth},
                {title: "Obtener configuración", test: getBTMConfig},
                {title: "Checar hardware", test: checkHardware},
            ];

            let i = 0;
            try {

                for (i = 0; i < tests.length; i++) {

                    const test = tests[i];

                    setCheck({title: test.title, state: 'loading'});
                    await test.test();
                    setCheck({title: test.title, state: 'success'})

                }

                navigate('/home');
            } catch (e) {
                timeout = setTimeout(checkAll, 5000);
                const test = tests[i];
                setCheck({title: test.title, state: 'fail', error: e.message})
                await btmLog('Boot test failed. ' + e.message);
                test.failCallback?.();
            }
        };

        checkAll().catch(console.error);

        return () => {
            clearTimeout(timeout);
        }

    },[setCheck, navigate]);

    const getIconForState = (state) => {
        switch (state) {
            case 'loading':
                return '⏱';
            case 'success':
                return '✅';
            case 'fail':
                return '❌';
            default:
                return '❓';
        }
    }


    return (
        <div className={"Boot"}>

            <OutOfService />

            <Header allowAdminScreen />

            <p className='title'>Cargando...</p>
            <ul>
                {checks.map((check) => (
                    <li key={check.title}>
                        {check.title} {check.error||''} {getIconForState(check.state)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Boot;
