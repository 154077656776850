import React from 'react';
import './ErrorBoundary.scss';
import Button from "../Button/Button";
import {btmLog} from "../../services/localBackendService";
import Layout from "../Layout/Layout";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {broken: false};
    }
    static getDerivedStateFromError() {
        return {broken: true};
    }

    componentDidCatch(error, errorInfo) {
        btmLog( "[Frontend Crash] " +  error.message ).catch(console.error);
        btmLog("[Frontend Crash Stack] " +errorInfo.componentStack).catch(console.error);
    }

    render(){

        if(!this.state.broken)
            return this.props.children;

        return (
            <Layout className='ErrorBoundary' showFooter={false}>
                <div className='right'>
                    <h1 className='title'>¡Oh no! Parece que hubo un error.</h1>
                    <h2 >Contacta a soporte técnico.</h2>
                    <div className='but-container'>
                        <a href='/'><Button >Regresar al inicio</Button></a>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ErrorBoundary;
