import React from 'react'
import InstructionsBox from '../InstructionsBox/InstructionsBox'
import Button from '../Button/Button'
import qrCode from '../../assets/images/qr-app.png';
import appStore from '../../assets/images/app-store.svg';
import playStoreBase from '../../assets/images/play-store-base.svg'
import playStoreText from '../../assets/images/play-store-text.svg'
import { useTranslation } from 'react-i18next';
import "./QrInfo.scss"

export default function QrInfo(
  {instructions, hideFirstParagraph, onClick}
) {

  const { t } = useTranslation();

  return (
    <div className='QrInfo'>
      <div className='content'>
        <div className='qr-section'>

          <div className='description'>
            {
              hideFirstParagraph &&
              <span>
                {t('Download the Rayo app')} <br /><br />
              </span>
            }
            {t('Scan the QR code to download the app on your device\'s store')}
          </div>
          <div className='qr-code'>
            <img src={qrCode} alt="qr-app" />
          </div>

          <div className='app-stores'>
            <div className='play-store'>
              <img src={playStoreBase} alt="google play" className='play-store-background'/>
              <img src={playStoreText} alt="google play text" className='play-store-text'/>
            </div>
            <div className='app-store'>
              <img src={appStore} alt="App Store" className='app-store-img'/>
            </div>
          </div>

        </div>
        <div>
          <InstructionsBox
            title={t('Secure transactions')}
            list={instructions}
            lastInfo={t('Your safety and comfort are our priority! Enjoy reliable and unlimited operations')}
            boxClass="box-instructions"
            titleClass="conditions-title"
          ></InstructionsBox>
          <div className='button'>
            <Button
              onClick={onClick}
              className='button-understood'
            >
              {t('Understood')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
