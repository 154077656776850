//Vamos a organizar las traducciones alfabéticamente
const translations = {
    "Accept": "Aceptar",
    "An error occurred while printing ticket": "Ocurrió un error al imprimir el ticket",
    "An error occurred while processing purchase": "Ocurrió un error al procesar tu compra",
    "Attention!": "¡Atención!",
    "Back": "Regresar",
    "Banknotes": "Billetes",
    "Banknotes inserted in session": "Billetes insertados en sesión",
    "Before starting": "Antes de comenzar",
    "Before starting, you need to know the terms of use of our BTMs and how we protect your privacy": "Antes de comenzar es necesario que conozcas los términos de uso de nuestros cajeros y cómo protegemos tu privacidad.",
    "Bills Accepted: ": "Billetes aceptados: ",
    "BTM Register": "Registro de cajero",
    "BTM registered successfully": "Cajero registrado correctamente",
    "BTM linked": "Cajero vinculado",
    "Buy": "Compra",
    "Buy Bitcoin": "Compra Bitcoin",
    "Buy terms": "Condiciones de compra",
    "Check the scanned QR. If the problem persists, contact support.": "Verifica el QR escaneado. Si persiste el problema, contacta a soporte.",
    "Choose the amount in pesos you want to sell, then its equivalent in BTC will be shown": "Elige la cantidad en pesos que quieres vender, a continuación se mostrará su equivalente en BTC.",
    "Choose your operation": "Escoge tu operación",
    "Code not sent": "Código no enviado",
    "Close": "Cerrar",
    "Continue": "Continuar",
    "Current limit is: ": "Tu límite actual es: {{amount}}",
    "Current transaction limit is: ": "Tu límite actual de transacción: {{amount}}",
    "day": "día",
    "Download the Rayo app": "Descarga la aplicación Rayyo® para aumentar tus límites.",
    "English": "Inglés",
    "Enjoy your cash and come back soon!": "¡Disfruta de tu efectivo y vuelve pronto!",
    "Enter server url": "Ingresa la url del servidor",
    "Enter your phone number": "Digita tu número celular",
    "Error linking BTM": "Error al vincular cajero",
    "Error getting kyc data": "Error al obtener información del usuario",
    "Error getting server url": "Error al obtener la url del servidor.",
    "Failed to create transaction": "Error al crear la transacción",
    "Failed to send ticket via sms": "No se pudo enviar el ticket vía SMS",
    "Failed to send verification code": "No se pudo enviar el código de verificación",
    "Finish": "Finalizar",
    "Finishing session with reason": "Terminando sesión por la razón: ",
    "I accept Cryptobox terms and conditions": "Acepto términos y condiciones de Cryptobox",
    "I accept privacy policy": "Acepto política de privacidad",
    "I will perform this operation on my own account and for my own benefit.": "Esta operación la realizaré por cuenta propia y en mi beneficio.",
    "If problem persist contact us": "Si el problema persiste contáctanos",
    "Identity not verified": "Identidad no verificada",
    "In case you do not confirm that the operation is for your benefit, you will need to register in our application.": "En caso de no confirmar que la operación es para su beneficio, deberá registrarse en nuestra aplicación.",
    "In your application, select the option to receive": "En tu aplicación, selecciona la opción de recibir.",
    "In your ticket you will find the instructions to continue": "En tu ticket encontrarás las instrucciones para continuar.",
    "Insert another bill": "Ingresa otro billete",
    "Incomplete payment": "Pago Incompleto",
    "Incorrect credentials": "Credenciales incorrectas",
    "Increase your limit!": "¡Aumenta tu límite!",
    "Insert cash": "Inserta el efectivo",
    "Insert the bills one by one": "Inserta los billetes uno por uno",
    "Instructions": "Instrucciones",
    "Invalid address": "Dirección inválida. Inténtalo de nuevo",
    "Invalid code": "Código inválido.",
    "Invalid phone number to make a transaction": "Número de teléfono inválido para realizar transacción",
    "Invalid QR code": "Código QR no válido",
    "leave any behind. We appreciate your trust and preference": "dejar ninguno atrás. Agradecemos tu confianza y preferencia.",
    "Limits for non-registered users": "<bold>Límites para usuarios no registrados</bold>: Hasta $2,000 diarios y $20,000 mensuales.",
    "Limits for registered users": "<bold>Límites para usuarios registrados:</bold> Hasta $12,000 por transacción, $30,000 diarios y $200,000 mensuales.",
    "Loading": "Cargando",
    "Loading exchange rates, wait a moment": "Cargando tipo de cambio, espere un momento",
    "Make sure the QR code is fully visible on the screen": "Asegúrate de que el código QR esté completamente visible en la pantalla.",
    "Maximum amount of transactions per day is $30,000 MXN": "Monto máximo de transacciones por {{period}} es de {{amount}} MXN",
    "Maximum amount per transaction of $12,000.00 MXN": "Monto máximo por transacción de {{amount}} MXN",
    "Multiple deposits": "Depositos multiples",
    "month": "mes",
    "Once the required quantity has been sent, you can": "Una vez enviada la cantidad requerida puede",
    "Only mxn banknotes": "Solo billetes MXN - Mínimo 500.00 MXN",
    "Open your wallet and select the option to send Bitcoin": "Abre tu wallet y selecciona la opción de enviar Bitcoin.",
    "Out of service": "Fuera de servicio",
    "Pending transaction": "Transacción Pendiente",
    "Phone number must have": "El número de teléfono debe tener al menos 8 dígitos",
    "Please remove your bills from the dispenser and make sure you do not": "Por favor, retira tus billetes del dispensador y asegúrate de no",
    "Please verify the operation and contact support if there is an error.": "Verifica la operación y contacta con soporte si hay un error.",
    "Printing ticket": "Imprimiendo ticket",
    "Privacy policy": "Política de privacidad",
    "Purchase": "Comprar",
    "QR Code doesn't exists": "EL Código QR no existe",
    "Reading register key": "Leyendo clave de registro...",
    "ReceiptMessage": "¡Hola! Puedes consultar tu transacción de cryptobox en el siguiente enlace: %s",
    "Redeem Ticket": "Redimir Ticket",
    "Redeemed ticket": "Ticket ya Cobrado",
    "Remember that the first bill you insert must be a 500 MXN bill to continue with the transaction.": "Recuerda que el primer billete que ingreses debe ser de $500 MXN para continuar con la transacción.",
    "Save": "Guardar",
    "Scan register key": "Escanear llave de registro",
    "Scan the QR code of your ticket": "Escanéa el código QR de tu ticket.",
    "Scan the QR code to download the app on your device's store": "Escanea el código QR para descargar la aplicación en la tienda de tu dispositivo.",
    "Scan your ticket": "Escanea tu ticket",
    "Scan your wallet": "Escanea tu wallet",
    "Secure transactions": "Transacciones seguras",
    "See privacy policy": "Ver política de privacidad",
    "See terms and conditions": "Ver términos y condiciones",
    "Sell": "Venta",
    "Sell Bitcoin": "Vender Bitcoin",
    "Sell_Bitcoin": "Vende Bitcoin",
    "Selling": "Vender",
    "Send": "Enviar",
    "Send your bitcoin": "Envía tus bitcoin",
    "Send_": "Envía",
    "Sent": "Enviado",
    "Server": "Servidor",
    "Server saved successfully": "Servidor guardado correctamente",
    "Session type not found": "No se encontró el sessionType ({{sessionType}}). Ir al inicio",
    "Spanish": "Español",
    "Starting session": "Iniciando sesión",
    "Successful transaction": "Transacción exitosa.",
    "take 5 to 30 minutes to fully reflect": "tardar de 5 a 30 minutos en reflejarse completamente.",
    "Take your receipt": "Toma tu recibo",
    "Terms and conditions": "Términos y condiciones",
    "Terms of use and privacy notice": "Términos de uso y aviso de privacidad",
    "Thanks for your preference!": "¡Gracias por tu preferencia!",
    "The combination of username and password is not valid. Please verify your information and try again.": "La combinación de usuario y contraseña no es válida. Verifica tus datos e inténtalo nuevamente.",
    "The BTM has been successfully linked. Rebooting in 5 seconds..." : "El cajero se ha vinculado correctamente. Reiniciando en 5 segundos...",
    "The first bill entered must be a denomination of $200.00 or $500.00 MXN": "El primer billete ingresado deberá ser denominación de $200.00 o $500.00 MXN",
    "The first bill entered must be $500": "El primer billete ingresado debe de ser de $500",
    "The minimum purchase is $500.00 MXN": "El mínimo de compra es de $500.00 MXN",
    "The QR code has not been paid in full. If the problem persists, contact support.": "El código QR no ha sido pagado en su totalidad. Si persiste el problema, contacta a soporte.",
    "The registration QR code is invalid or has expired. Verify the QR or generate a new one.": "El código QR de registro no es válido o ha caducado. Verifica el QR o genera uno nuevo.",
    "The transaction may take up to 60 minutes to be reflected, depending on network saturation": "La transacción puede tardar en verse reflejada hasta 60 minutos, dependiendo de la saturación en la red.",
    "This BTM accepts bills of any denomination": "Este cajero acepta billetes de cualquier denominación.",
    "This BTM does not accept coins": "Este cajero no acepta monedas.",
    "This BTM does not accept torn, stapled, bent or mistreated bills": "Este cajero no acepta billetes rotos, engrapados, doblados o maltratados.",
    "This BTM does not give change": "Este cajero no da cambio",
    "This BTM only accepts Mexican pesos": "Este cajero solo acepta pesos mexicanos.",
    "There was a problem trying to process the transaction. Please try again.": "Ocurrió un problema al intentar realizar la transacción. Por favor, inténtalo de nuevo.",
    "Transaction error": "Error en transacción",
    "Ticket already charged": "El ticket ya ha sido cobrado.",
    "to withdraw": "A retirar",
    "Total": "Total",
    "Total charged": "Total cobrado",
    "Total inserted": "Total ingresado",
    "Transaction has not been paid": "La transacción no ha sido pagada.",
    "Transaction limit reached": "Límite de transacción alcanzado",
    "Try scanning the ticket later. If the problem persists, contact support.": "Intenta escaneando el ticket más tarde. Si persiste el problema, contacta a soporte.",
    "Try scanning ticket later": "Intenta escaneando el ticket más tarde.",
    "Understood": "Entendido",
    "Unknown server error": "Error desconocido del servidor",
    "Use the reader at the bottom left of the BTM to scan the QR code of your Wallet": "Utiliza el lector de la parte inferior izquierda del cajero para escanear el código QR de tu Wallet.",
    "Use your wallet to complete the transaction": "Utiliza tu wallet para completar la transacción",
    "Verification code": "Código enviado a ",
    "Verification code not valid": "El código de verificación es incorrecto",
    "Verification is only once and is valid for all Cryptobox BTMs in the network": "La verificación es por única vez y es válida para todos los cajeros Cryptobox de la red.",
    "Verification requirements: INE, RFC with homoclave and verify economic activity": "<bold>Requisitos de verificación:</bold> INE, RFC con homoclave y verificar actividad económica.",
    "Verified identity": "Identidad verificada",
    "Verified phone number continuing the transaction": "Número de teléfono verificado continuando con la transacción",
    "Verification required": "Verificación requerida",
    "Verify": "Verificar",
    "Wait for the transaction to be confirmed": "Espera a que la transacción sea confirmada.",
    "We are sorry, but you've reached your transaction limit for today": "Lo sentimos, pero has alcanzado tu límite de transacciones por hoy. Como usuario registrado, cuentas con un límite de $30,000 diarios y $200,000 por mes para transacciones.",
    "We are sorry, we do not have enough funds to dispense the transaction. Please try again later.": "Lo sentimos, no contamos con suficientes fondos para dispensar la transacción. Por favor intentalo más tarde.",
    "We cannot send the verification code at this time. Please try again later.": "No podemos enviar el código de verificación en este momento. Inténtalo más tarde.",
    "We have sent an SMS with the transaction ticket to the number you have provided us": "Hemos enviado un SMS con el ticket de transacción al número que nos has proporcionado.",
    "We look forward to seeing you back soon to continue operating with us": "¡Te esperamos de vuelta pronto para seguir operando con nosotros!",
    "We send a verification code": "Hemos enviado un código de verificación por SMS al número proporcionado. Ingresa el código de verificación para continuar.",
    "The QR code has not been completely payed. Contact support": "El código QR no ha sido pagado en su totalidad. Si persiste el problema, contacta a soporte.",
    "We have detected a larger deposit related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount.": "Hemos detectado un depósito mayor relacionado a tu venta, la operación no se pudo concretar. Por favor comunicarte a servicio a cliente.",
    "We have detected multiple deposits related to your sale, and the operation could not be completed. Please contact customer service to return the deposited amount.": "Hemos detectado varios depósitos relacionado a tu venta, la operación no se pudo concretar. Por favor comunicarte a servicio a cliente.",
    "Wrong amount": "Cantidad incorrecta",
    "Withdraw your cash": "Retira tu efectivo",
    "You have 10 to 15 minutes to send the transaction": "Tienes de 10 a 15 minutos para enviar la transacción.",
    "You must enter an amount": "Debes ingresar una cantidad",
    "You will receive your money in cash": "¡Listo! Recibirás tu dinero en efectivo.",
    "Your safety and comfort are our priority! Enjoy reliable and unlimited operations": "¡Tu seguridad y comodidad son nuestra prioridad! Disfruta de operaciones confiables y sin limites.",
    "Your ticket": "Tu ticket",
    "Your transaction": "Tu transacción",
    "Your transaction is in progress!": "¡Tu transacción está en proceso!",
    "Your verification code is": "Tu código es: {{code}}",
    "Your wallet": "Tu wallet: "
}

export default translations;
