import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function operatorLogin( { username, password } ) {
    return localHttpApi.callApi({
        path: "/operators/login",
        method: "POST",
        params: { username, password }
    });
}
