import React, { useCallback, useState } from 'react'
import assistant from "../../assets/images/help_assistant.svg";
import "./Assistant.scss"
import HelpInfo from '../HelpInfo/HelpInfo';

export default function Assistant() {
  const [showInfo, setShowInfo] = useState(false)

  const handleAssistantClick = useCallback(() => {
    const currentState = showInfo;
    setShowInfo(!currentState)
  }, [showInfo])

  return (
    <div className='Assistant'>
      {
        !showInfo &&
        <div className='assistant'>
          <img src={assistant} alt="help" onClick={handleAssistantClick} />
        </div>
      }
      
      {
        showInfo &&
        <div className='help-info'>
          <div className='help-container'>
            <HelpInfo
              onClose={handleAssistantClick}
            ></HelpInfo>
          </div>
        </div>
      }
    </div>
  )
}
