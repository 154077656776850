import React, {useCallback, useEffect} from 'react'
import Rate from '../../components/Rate/Rate'
import Wallet from '../../components/Wallet/Wallet'
import "./TakeYourTicket.scss"
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import InstructionsBox from '../../components/InstructionsBox/InstructionsBox'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import { secondsToTimer } from '../../services/constants'
import {useSelector} from "react-redux";
import {numberBtcFormat} from "../../utils/btcUtils";
import {formatMoney} from "../../utils/numberUtils";
import {useNavigate} from "react-router-dom";
import {paths} from "../../services/routes/appRoutes";
import {btmLog} from "../../services/localBackendService";
import { useTranslation } from 'react-i18next';

export default function TakeYourTicket() {

  useEffect(() => {
    btmLog('TakeYourTicket screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const session = useSelector((state) => state.session);
  const {buyPrice, userWallet, insertedBanknotes} = session;

  const moneyAdded = insertedBanknotes?.reduce((acc, curr) => acc + curr, 0) || 0;

  const onContinue = ()=>{
    btmLog(`Finish session successfully with standard button. Session summary: ${JSON.stringify(session)}`)
        .catch(console.log);
    navigate(paths.home);
  }

  const onTimeout = useCallback(() => {
    btmLog(`Finish session successfully with timeout. Session summary: ${JSON.stringify(session)}`)
        .catch(console.log);
    navigate(paths.home);
  }, [navigate, session])

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      hideButtons={true}
    >
      <div className='TakeYourTicket'>
        <div className='title text-center'>
          <Title
            text={t('Thanks for your preference!')}
          ></Title>
        </div>
        <div className='mt-4 d-flex justify-content-between'>
          <Rate
            text={t('Buy')}
            value={buyPrice}
          />
          <CircleTimer
            seconds={secondsToTimer}
            callback={onTimeout}
          />
        </div>
        <div className='d-flex justify-content-center mt-5'>
          <Wallet address={userWallet} />
        </div>
        <div className='mt-5'>
          <div className='d-flex justify-content-between'>
            <div className='box-info'>
              <div>
                <span className='box-info-title'>
                  {t('Sent')}
                </span>
                <br />
                <span className='box-info-text-green'>
                  {numberBtcFormat(moneyAdded / buyPrice)} BTC
                </span>
              </div>
              <div className='box-info-separator'>&nbsp;</div>
              <div>
                <span className='box-info-title'>
                  {t('Total charged')}
                </span>
                <br />
                <span className='box-info-text-orange'>
                  ${formatMoney(moneyAdded)} MXN
                </span>
              </div>
            </div>
            <div>
              <InstructionsBox
                title={t('Your transaction')}
                list={[
                  t('We have sent an SMS with the transaction ticket to the number you have provided us'),
                  t('The transaction may take up to 60 minutes to be reflected, depending on network saturation')
                ]}
                boxClass="box-instructions"
                titleClass="box-instructions-title"
              ></InstructionsBox>
            </div>
          </div>
        </div>
        <div className='mt-5 d-flex justify-content-center'>
          <div>
            <Button
              onClick={onContinue}
            >
              {t('Understood')}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}
