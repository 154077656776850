import LocalWsApi from "../LocalWsApi";

const controllerPrefix = 'ticketPrinter/';

export default class TicketPrinterClient {
    constructor() {
        this.wsApi = LocalWsApi.getInstance();
    }

    async print(ticket) {
        try {
            await this.wsApi.callApi({path: controllerPrefix + 'print', params: ticket});
        } catch (e) {
            console.error(`TicketPrinterClient - print - error: ${e}`);
            throw e;
        }
    }

    /**
     * Print a ticket for buying BTC
     * See back/services/printingService.js to see the ticket structure
     *
     * @param ticketInfo
     * @returns {Promise<void>}
     */
    async printBuyBtcTicket(ticketInfo) {
        try {
            await this.wsApi.callApi({path: controllerPrefix + 'printBuyBtcTicket', params: ticketInfo});
        } catch (e) {
            console.error(`TicketPrinterClient - print - error: ${e}`);
            throw e;
        }
    }

    /**
     * Print a ticket for creating a new address to receive BTC
     * See back/services/printingService.js to see the ticket structure
     *
     * @param ticketInfo
     * @returns {Promise<void>}
     */
    async printReceiveAddressTicket(ticketInfo) {
        try {
            await this.wsApi.callApi({path: controllerPrefix + 'printReceiveAddressTicket', params: ticketInfo});
        } catch (e) {
            console.error(`TicketPrinterClient - print - error: ${e}`);
            throw e;
        }
    }

    async getStatus() {
        try {
            return await this.wsApi.callApi({path: controllerPrefix + 'getStatus'});
        } catch (e) {
            console.error(`TicketPrinterClient - getStatus - error: ${e}`);
            throw e;
        }
    }

    on(event, listener) {
        this.wsApi.subscribe(event, listener);
    }

    off(event, listener) {
        this.wsApi.unsubscribe(event, listener);
    }
}
