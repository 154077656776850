export const countryCodesList = [
    { countryCode: '93', isoCode: 'AFG' },
    { countryCode: '355', isoCode: 'ALB' },
    { countryCode: '213', isoCode: 'DZA' },
    { countryCode: '1684', isoCode: 'ASM' },
    { countryCode: '376', isoCode: 'AND' },
    { countryCode: '244', isoCode: 'AGO' },
    { countryCode: '1264', isoCode: 'AIA' },
    { countryCode: '672', isoCode: 'ATA' },
    { countryCode: '1268', isoCode: 'ATG' },
    { countryCode: '54', isoCode: 'ARG' },
    { countryCode: '374', isoCode: 'ARM' },
    { countryCode: '297', isoCode: 'ABW' },
    { countryCode: '61', isoCode: 'AUS' },
    { countryCode: '43', isoCode: 'AUT' },
    { countryCode: '994', isoCode: 'AZE' },
    { countryCode: '1242', isoCode: 'BHS' },
    { countryCode: '973', isoCode: 'BHR' },
    { countryCode: '880', isoCode: 'BGD' },
    { countryCode: '1246', isoCode: 'BRB' },
    { countryCode: '375', isoCode: 'BLR' },
    { countryCode: '32', isoCode: 'BEL' },
    { countryCode: '501', isoCode: 'BLZ' },
    { countryCode: '229', isoCode: 'BEN' },
    { countryCode: '1441', isoCode: 'BMU' },
    { countryCode: '975', isoCode: 'BTN' },
    { countryCode: '591', isoCode: 'BOL' },
    { countryCode: '599', isoCode: 'BES' },
    { countryCode: '387', isoCode: 'BIH' },
    { countryCode: '267', isoCode: 'BWA' },
    { countryCode: '55', isoCode: 'BRA' },
    { countryCode: '246', isoCode: 'IOT' },
    { countryCode: '1284', isoCode: 'VGB' },
    { countryCode: '673', isoCode: 'BRN' },
    { countryCode: '359', isoCode: 'BGR' },
    { countryCode: '226', isoCode: 'BFA' },
    { countryCode: '257', isoCode: 'BDI' },
    { countryCode: '855', isoCode: 'KHM' },
    { countryCode: '237', isoCode: 'CMR' },
    { countryCode: '1', isoCode: 'CAN' },
    { countryCode: '238', isoCode: 'CPV' },
    { countryCode: '1345', isoCode: 'CYM' },
    { countryCode: '236', isoCode: 'CAF' },
    { countryCode: '235', isoCode: 'TCD' },
    { countryCode: '56', isoCode: 'CHL' },
    { countryCode: '86', isoCode: 'CHN' },
    { countryCode: '61', isoCode: 'CXR' },
    { countryCode: '61', isoCode: 'CCK' },
    { countryCode: '57', isoCode: 'COL' },
    { countryCode: '269', isoCode: 'COM' },
    { countryCode: '682', isoCode: 'COK' },
    { countryCode: '506', isoCode: 'CRI' },
    { countryCode: '385', isoCode: 'HRV' },
    { countryCode: '53', isoCode: 'CUB' },
    { countryCode: '599', isoCode: 'CUW' },
    { countryCode: '357', isoCode: 'CYP' },
    { countryCode: '420', isoCode: 'CZE' },
    { countryCode: '243', isoCode: 'COD' },
    { countryCode: '45', isoCode: 'DNK' },
    { countryCode: '253', isoCode: 'DJI' },
    { countryCode: '1767', isoCode: 'DMA' },
    { countryCode: '1809', isoCode: 'DOM' },
    { countryCode: '670', isoCode: 'TLS' },
    { countryCode: '593', isoCode: 'ECU' },
    { countryCode: '20', isoCode: 'EGY' },
    { countryCode: '503', isoCode: 'SLV' },
    { countryCode: '240', isoCode: 'GNQ' },
    { countryCode: '291', isoCode: 'ERI' },
    { countryCode: '372', isoCode: 'EST' },
    { countryCode: '268', isoCode: 'SWZ' },
    { countryCode: '251', isoCode: 'ETH' },
    { countryCode: '500', isoCode: 'FLK' },
    { countryCode: '298', isoCode: 'FRO' },
    { countryCode: '679', isoCode: 'FJI' },
    { countryCode: '358', isoCode: 'FIN' },
    { countryCode: '33', isoCode: 'FRA' },
    { countryCode: '594', isoCode: 'GUF' },
    { countryCode: '689', isoCode: 'PYF' },
    { countryCode: '241', isoCode: 'GAB' },
    { countryCode: '220', isoCode: 'GMB' },
    { countryCode: '995', isoCode: 'GEO' },
    { countryCode: '49', isoCode: 'DEU' },
    { countryCode: '233', isoCode: 'GHA' },
    { countryCode: '350', isoCode: 'GIB' },
    { countryCode: '30', isoCode: 'GRC' },
    { countryCode: '299', isoCode: 'GRL' },
    { countryCode: '1473', isoCode: 'GRD' },
    { countryCode: '590', isoCode: 'GLP' },
    { countryCode: '1671', isoCode: 'GUM' },
    { countryCode: '502', isoCode: 'GTM' },
    { countryCode: '441481', isoCode: 'GGY' },
    { countryCode: '224', isoCode: 'GIN' },
    { countryCode: '245', isoCode: 'GNB' },
    { countryCode: '592', isoCode: 'GUY' },
    { countryCode: '509', isoCode: 'HTI' },
    { countryCode: '504', isoCode: 'HND' },
    { countryCode: '852', isoCode: 'HKG' },
    { countryCode: '36', isoCode: 'HUN' },
    { countryCode: '354', isoCode: 'ISL' },
    { countryCode: '91', isoCode: 'IND' },
    { countryCode: '62', isoCode: 'IDN' },
    { countryCode: '98', isoCode: 'IRN' },
    { countryCode: '964', isoCode: 'IRQ' },
    { countryCode: '353', isoCode: 'IRL' },
    { countryCode: '441624', isoCode: 'IMN' },
    { countryCode: '972', isoCode: 'ISR' },
    { countryCode: '39', isoCode: 'ITA' },
    { countryCode: '225', isoCode: 'CIV' },
    { countryCode: '1876', isoCode: 'JAM' },
    { countryCode: '81', isoCode: 'JPN' },
    { countryCode: '441534', isoCode: 'JEY' },
    { countryCode: '962', isoCode: 'JOR' },
    { countryCode: '7', isoCode: 'KAZ' },
    { countryCode: '254', isoCode: 'KEN' },
    { countryCode: '686', isoCode: 'KIR' },
    { countryCode: '383', isoCode: 'XK' },
    { countryCode: '965', isoCode: 'KWT' },
    { countryCode: '996', isoCode: 'KGZ' },
    { countryCode: '856', isoCode: 'LAO' },
    { countryCode: '371', isoCode: 'LVA' },
    { countryCode: '961', isoCode: 'LBN' },
    { countryCode: '266', isoCode: 'LSO' },
    { countryCode: '231', isoCode: 'LBR' },
    { countryCode: '218', isoCode: 'LBY' },
    { countryCode: '423', isoCode: 'LIE' },
    { countryCode: '370', isoCode: 'LTU' },
    { countryCode: '352', isoCode: 'LUX' },
    { countryCode: '853', isoCode: 'MAC' },
    { countryCode: '261', isoCode: 'MDG' },
    { countryCode: '265', isoCode: 'MWI' },
    { countryCode: '60', isoCode: 'MYS' },
    { countryCode: '960', isoCode: 'MDV' },
    { countryCode: '223', isoCode: 'MLI' },
    { countryCode: '356', isoCode: 'MLT' },
    { countryCode: '692', isoCode: 'MHL' },
    { countryCode: '596', isoCode: 'MTQ' },
    { countryCode: '222', isoCode: 'MRT' },
    { countryCode: '230', isoCode: 'MUS' },
    { countryCode: '262', isoCode: 'MYT' },
    { countryCode: '52', isoCode: 'MEX' },
    { countryCode: '691', isoCode: 'FSM' },
    { countryCode: '373', isoCode: 'MDA' },
    { countryCode: '377', isoCode: 'MCO' },
    { countryCode: '976', isoCode: 'MNG' },
    { countryCode: '382', isoCode: 'MNE' },
    { countryCode: '1664', isoCode: 'MSR' },
    { countryCode: '212', isoCode: 'MAR' },
    { countryCode: '258', isoCode: 'MOZ' },
    { countryCode: '95', isoCode: 'MMR' },
    { countryCode: '264', isoCode: 'NAM' },
    { countryCode: '674', isoCode: 'NRU' },
    { countryCode: '977', isoCode: 'NPL' },
    { countryCode: '31', isoCode: 'NLD' },
    { countryCode: '687', isoCode: 'NCL' },
    { countryCode: '64', isoCode: 'NZL' },
    { countryCode: '505', isoCode: 'NIC' },
    { countryCode: '227', isoCode: 'NER' },
    { countryCode: '234', isoCode: 'NGA' },
    { countryCode: '683', isoCode: 'NIU' },
    { countryCode: '672', isoCode: 'NFK' },
    { countryCode: '850', isoCode: 'KOR' },
    { countryCode: '389', isoCode: 'MKD' },
    { countryCode: '1670', isoCode: 'MNP' },
    { countryCode: '47', isoCode: 'NOR' },
    { countryCode: '968', isoCode: 'OMN' },
    { countryCode: '92', isoCode: 'PAK' },
    { countryCode: '680', isoCode: 'PLW' },
    { countryCode: '970', isoCode: 'PSE' },
    { countryCode: '507', isoCode: 'PAN' },
    { countryCode: '675', isoCode: 'PNG' },
    { countryCode: '595', isoCode: 'PRY' },
    { countryCode: '51', isoCode: 'PER' },
    { countryCode: '63', isoCode: 'PHL' },
    { countryCode: '64', isoCode: 'PCN' },
    { countryCode: '48', isoCode: 'POL' },
    { countryCode: '351', isoCode: 'PRT' },
    { countryCode: '1787', isoCode: 'PRI' },
    { countryCode: '974', isoCode: 'QAT' },
    { countryCode: '242', isoCode: 'COG' },
    { countryCode: '262', isoCode: 'REU' },
    { countryCode: '40', isoCode: 'ROU' },
    { countryCode: '7', isoCode: 'RUS' },
    { countryCode: '250', isoCode: 'RWA' },
    { countryCode: '590', isoCode: 'BLM' },
    { countryCode: '290', isoCode: 'SHN' },
    { countryCode: '1869', isoCode: 'KNA' },
    { countryCode: '1758', isoCode: 'LCA' },
    { countryCode: '590', isoCode: 'MAF' },
    { countryCode: '508', isoCode: 'SPM' },
    { countryCode: '1784', isoCode: 'VCT' },
    { countryCode: '685', isoCode: 'WSM' },
    { countryCode: '378', isoCode: 'SMR' },
    { countryCode: '239', isoCode: 'STP' },
    { countryCode: '966', isoCode: 'SAU' },
    { countryCode: '221', isoCode: 'SEN' },
    { countryCode: '381', isoCode: 'SRB' },
    { countryCode: '248', isoCode: 'SYC' },
    { countryCode: '232', isoCode: 'SLE' },
    { countryCode: '65', isoCode: 'SGP' },
    { countryCode: '1721', isoCode: 'SXM' },
    { countryCode: '421', isoCode: 'SVK' },
    { countryCode: '386', isoCode: 'SVN' },
    { countryCode: '677', isoCode: 'SLB' },
    { countryCode: '252', isoCode: 'SOM' },
    { countryCode: '27', isoCode: 'ZAF' },
    { countryCode: '82', isoCode: 'KOR' },
    { countryCode: '211', isoCode: 'SSD' },
    { countryCode: '34', isoCode: 'ESP' },
    { countryCode: '94', isoCode: 'LKA' },
    { countryCode: '249', isoCode: 'SDN' },
    { countryCode: '597', isoCode: 'SUR' },
    { countryCode: '47', isoCode: 'SJM' },
    { countryCode: '46', isoCode: 'SWE' },
    { countryCode: '41', isoCode: 'CHE' },
    { countryCode: '963', isoCode: 'SYR' },
    { countryCode: '886', isoCode: 'TWN' },
    { countryCode: '992', isoCode: 'TJK' },
    { countryCode: '255', isoCode: 'TZA' },
    { countryCode: '66', isoCode: 'THA' },
    { countryCode: '228', isoCode: 'TGO' },
    { countryCode: '690', isoCode: 'TKL' },
    { countryCode: '676', isoCode: 'TON' },
    { countryCode: '1868', isoCode: 'TTO' },
    { countryCode: '216', isoCode: 'TUN' },
    { countryCode: '90', isoCode: 'TUR' },
    { countryCode: '993', isoCode: 'TKM' },
    { countryCode: '1649', isoCode: 'TCA' },
    { countryCode: '688', isoCode: 'TUV' },
    { countryCode: '256', isoCode: 'UGA' },
    { countryCode: '380', isoCode: 'UKR' },
    { countryCode: '971', isoCode: 'ARE' },
    { countryCode: '441', isoCode: 'GBR' },
    { countryCode: '1', isoCode: 'USA' },
    { countryCode: '598', isoCode: 'URY' },
    { countryCode: '1340', isoCode: 'VIR' },
    { countryCode: '998', isoCode: 'UZB' },
    { countryCode: '678', isoCode: 'VUT' },
    { countryCode: '379', isoCode: 'VAT' },
    { countryCode: '58', isoCode: 'VEN' },
    { countryCode: '84', isoCode: 'VNM' },
    { countryCode: '681', isoCode: 'WLF' },
    { countryCode: '212', isoCode: 'ESH' },
    { countryCode: '967', isoCode: 'YEM' },
    { countryCode: '260', isoCode: 'ZMB' },
    { countryCode: '263', isoCode: 'ZWE' }
];