import React from 'react'
import "./TermsAndConditions.scss"
import Title from '../../../../components/Title/Title'
import Button from '../../../../components/Button/Button'
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions(
  {title, content, onClose, onAccept}
) {
  const { t } = useTranslation();

  return (
    <div className='TermsAndConditions'>
      <div className='d-flex justify-content-center'>
        <Title
          text={title}
        ></Title>
      </div>
      <div className='mt-4 terms-content' dangerouslySetInnerHTML={{__html: content}}>
        {/* {content} */}
      </div>
      <div className='mt-4 d-flex flex-col gap-4'>
        <div>
          <Button
            type="red"
            className="button-close"
            onClick={onClose}
          >
            {t('Close')}
          </Button>
        </div>
        <div>
          <Button
            className="button-accept"
            onClick={onAccept}
          >
            {t('Accept')}
          </Button>
        </div>
      </div>
    </div>
  )
}
