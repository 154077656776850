import React, { useEffect, useState } from 'react'
import "./OutOfService.scss"
import CheckBtmStatusService from '../../services/CheckBtmStatusService';
import { useTranslation } from 'react-i18next';
import FullScreenAlert from '../../components/FullScreenAlert/FullScreenAlert';
import loadingError from '../../assets/images/info-red-animated.gif'
import {btmLog} from "../../services/localBackendService";
import logo from "../../assets/images/logo_cryptobox.svg";
import {useNavigate} from "react-router-dom";
import {paths} from "../../services/routes/appRoutes";

export default function OutOfService() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [outOfServiceStatus, setOutOfServiceStatus] = useState({});

  // Check the BTM status
  useEffect(()=>{
    const checkBtmStatusService = new CheckBtmStatusService();

    // fetchStatus fires an event onStatusChange when the status is ready
    checkBtmStatusService.fetchStatus()
        .then(()=>{
          setOutOfServiceStatus( checkBtmStatusService.getOutOfService() );
        })
      .catch((error)=>{
        btmLog('Error checking BTM status. ' + error);
        console.error(error);
    });

    // handle status changes
    const statusListener = ()=>{
      setOutOfServiceStatus( checkBtmStatusService.getOutOfService() );
    }

    checkBtmStatusService.onStatusChange(statusListener);
    return ()=>checkBtmStatusService.removeListener(statusListener);
  },[]);

  const showLogoScreen = outOfServiceStatus?.code === 106; // Btm not linked

  // Admin screen navigation
  //Listen to the admin screen navigation
  const [clickCount, setClickCount] = useState(0);
  useEffect(() => {
    let timer;
    if (clickCount === 9) {
      navigate(paths.login);
    } else if (clickCount > 0) {
      timer = setTimeout(() => {
        setClickCount(0);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [clickCount, navigate]);

  const handleClick = () => {
    setClickCount(prevCount => prevCount + 1);
  };

  return (
    !!outOfServiceStatus?.outOfService &&
    <FullScreenAlert layoutProps={{showHeader:!showLogoScreen}}>
      <div className='OutOfService'>
        <div className='content'>

          {!showLogoScreen &&
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
              <img src={loadingError} alt="Error icon"  className='loader-error'/>
            </div>
            <div className='title'>
              {t('Out of service')}
            </div>
            <div className='description'>
              {outOfServiceStatus?.code || 'N/A'}
            </div>
          </div>}

          {showLogoScreen &&
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
              <img src={logo} alt="logo" className='central-logo' onClick={handleClick}/>
            </div>
          </div>}


        </div>
      </div>
    </FullScreenAlert>
  )
}
