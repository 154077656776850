import React, {useCallback, useEffect} from 'react'
import "./VerifyIdentity.scss"
import Layout from '../../components/Layout/Layout'
import {paths} from '../../services/routes/appRoutes';
import {useNavigate} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import CircleTimer from '../../components/CircleTimer/CircleTimer';
import {secondsToTimer} from '../../services/constants';
import Title from '../../components/Title/Title';
import QrInfo from '../../components/QrInfo/QrInfo';
import {btmLog} from "../../services/localBackendService";

export default function VerifyIdentity() {

  useEffect(() => {
    btmLog('VerifyIdentity screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const {t} = useTranslation();

  const onBack = useCallback(() => {
    navigate(paths.home);
  }, [navigate])

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      backButtonCallback={onBack}
      hideButtons={false}
    >
      <div className="VerifyIdentity">
        <div className='d-flex justify-content-end'>
          <CircleTimer
            seconds={secondsToTimer}
            callback={onBack}
          />
        </div>
        <div className='title text-center'>
          <Title text={t('Identity not verified')}></Title>

        </div>
        <div className='description'>Descarga la aplicación Rayyo® para verificar tu identidad (opcional) y así poder
          aumentar tus limites
        </div>

      </div>
      <div className='qr-content'>
        <QrInfo
          onClick={onBack}
          instructions={[
            <Trans key={1} components={{bold: <strong/>}} i18nKey={'Limits for registered users'}></Trans>,
            <Trans key={1} components={{bold: <strong/>}} i18nKey={'Limits for non-registered users'}></Trans>,
            <Trans key={2} components={{bold: <strong/>}}
                   i18nKey={'Verification requirements: INE, RFC with homoclave and verify economic activity'}></Trans>,
            <Trans key={3} components={{bold: <strong/>}}
                   i18nKey={'Verification is only once and is valid for all Cryptobox BTMs in the network'}></Trans>
          ]}
        ></QrInfo>
      </div>
    </Layout>
  )
}