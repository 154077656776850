import React, {useEffect, useState} from 'react';
import './LedsTest.scss';
import {blinkLed, ledId, turnOffLed} from "../../../../../services/hardware/ledsService";
import {getLedsConfig} from "../../../../../services/hardware/hardwareConfig";
import {btmLog} from "../../../../../services/localBackendService";
import _ from "lodash";
import classNames from "classnames";

const LedsTest = () => {


    const [config, setConfig] = useState();
    const [onLeds, setOnLeds] = useState([]);

    useEffect(() => {

        // Get QR reader config
        getLedsConfig().then(config => {
            setConfig(config?.data);
        }).catch(() => {
            btmLog("No se pudo obtener la configuración de los leds");
        });

        // Turn on QR led
        blinkLed(ledId.QR_READER).catch(console.error);

        return () => {
            turnOffLed(ledId.QR_READER).catch(console.error);
        }
    }, []);

    const renderConfig = () => {
        if(!config) return null;
        const configs = [];
        _.forEach(config, (value, key) => {
            return configs.push( <p className='read-result-value' key={key}>{key}: {value}</p> );
        })
        return configs;
    }

    const toggleLed = (led) => {

        if(onLeds.includes(led)){
            turnOffLed(led).then(()=>{
                setOnLeds(onLeds.filter(l => l !== led));
            }).catch(console.error);
        } else {
            blinkLed(led).then(()=>{
                setOnLeds([...onLeds, led]);
            }).catch(console.error);
        }

    }



    return (
        <div className={"LedsTest"}>
            <h1 className='title'>Leds</h1>
            <p className='instructions'>Selecciona un botón para prender o apagar un led del cajero.</p>

            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='read-result'>
                            <p className='read-result-title'>Configuración:</p>
                            {renderConfig()}

                            <div className='led-btn-container'>

                                {(()=>{
                                    const buttons = [];
                                    _.forEach(ledId, (value, key) => {
                                        buttons.push(
                                            <button key={key} className='led-btn' onClick={()=>toggleLed(value)}>{key}</button>
                                        );
                                    })
                                    return buttons;
                                })()}

                            </div>

                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='leds-display'>
                            {(()=>{
                                const leds = [];
                                _.forEach(ledId, (value, key) => {
                                    leds.push(
                                        <div className={ classNames('single-led', {active: onLeds.includes(value)})} key={key} />
                                    );
                                })
                                return leds;
                            })()}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default LedsTest;
