import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Rate from '../../components/Rate/Rate'
import { paths } from "../../services/routes/appRoutes"
import "./SendBitcoin.scss"
import Button from '../../components/Button/Button'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import InstructionsBox from '../../components/InstructionsBox/InstructionsBox'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import { secondsToTimer } from '../../services/constants'
import {useSelector} from "react-redux";
import {moneyFormatter} from "../../services/currencyUtils";
import {numberBtcFormat} from "../../utils/btcUtils";
import {btmLog} from "../../services/localBackendService";
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";

export default function SendBitcoin() {

  useEffect(() => {
    btmLog('SendBitcoin screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const session = useSelector((state) => state.session);
  const { sellPrice, requestedAmount, ticketData } = session;
  const bitcoinAmount = requestedAmount / sellPrice;

  const handleContinue = async (reason) => {
    navigate(paths.printTicket);
    await btmLog(`Finish session successfully with ${reason}. Session summary: ${JSON.stringify(session)}`);
  };

  const getUriQr = ({ address, btcAmount }) => {
    const params = {
      amount: btcAmount / 100_000_000,
      label: 'Cryptobox'
    };

    const paramsStr = Object.entries(params)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    return `bitcoin:${address}${paramsStr ? '?' + paramsStr : ''}`;
  }

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      hideButtons={true}
    >
      <div className='SendBitcoin'>
        <div className='d-flex justify-content-between'>
          <Rate
            text={t('Sell')}
            value={sellPrice}
          />
          <CircleTimer
            seconds={secondsToTimer}
            callback={()=>handleContinue('timeout')}
          />
        </div>
        <div className='title text-center mt-4'>
          <Title
            text={t('Send your bitcoin')}
          ></Title>
        </div>
        <div className='d-flex justify-content-center mt-5'>
          <span className='subtitle'>
            {t('Use your wallet to complete the transaction')}
          </span>
        </div>
        <div className='mt-4'>
          <div className='d-flex justify-content-between'>
            <div className="box-content">
              <div className='box-qr'>
                <QRCode value={getUriQr(ticketData)} fgColor="#1A0936"/>
              </div>
              <div className='box-info'>
                <div className='mt-2'>
                <span className='box-info-title'>
                  {t('Send_')}
                </span>
                  <br />
                  <span className='box-info-text-green'>
                  {numberBtcFormat(bitcoinAmount)} BTC
                </span>
                </div>
                <div className='box-info-separator'>&nbsp;</div>
                <div>
                <span className='box-info-title'>
                  {t('to withdraw')}
                </span>
                  <br />
                  <span className='box-info-text-orange'>
                  {moneyFormatter(requestedAmount)} MXN
                </span>
                </div>
              </div>
              <div>
            </div>
          </div>
          <InstructionsBox
              title={t('Your ticket')}
              list={[
                t('In your ticket you will find the instructions to continue'),
                t('You have 10 to 15 minutes to send the transaction'),
                t('We have sent an SMS with the transaction ticket to the number you have provided us')
              ]}
              boxClass="box-instructions"
              titleClass="box-instructions-title"
          ></InstructionsBox>
        </div>
        </div>
        <div className='mt-5 d-flex justify-content-center'>
          <div>
            <Button
              onClick={()=>handleContinue('standard button')}
            >
              {t('Understood')}
            </Button>
          </div>
        </div>
      </div>
    </Layout>

  )
}
