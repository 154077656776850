import React from 'react'
import { numericValues } from "./NumericPadUtils"
import _ from "lodash"
import BackIcon from "../../assets/images/delete-left.svg"
import "./NumericPad.scss"

export default function NumericPad(
  {
    onPress
  }
) {
  return (
    <div className='NumericPad'>
      <div className='grid grid-cols-3 gap-2'>
        {
          _.map(numericValues, (numeric, index) => (
            <div
              key={index}
              className='numeric-box cursor-pointer'
              onClick={
                () => onPress(numeric.value)
              }
            >
                <span className='numeric-label'>
                  {
                    numeric.label === 'Back'
                      ? <img src={BackIcon} alt="delete" />
                      : numeric.label
                  }
                </span>
            </div>
          ))
        }
      </div>
    </div>
  )
}
