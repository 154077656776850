import LocalHttpApi from "../LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function turnOffLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/turnOff",
        method: "POST",
        params: { ledId },
    });
}

export async function blinkLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/blink",
        method: "POST",
        params: { ledId },
    });
}

export async function turnOnLed(ledId) {
    return localHttpApi.callApi({
        path: "/leds/turnOn",
        method: "POST",
        params: { ledId },
    });
}

export const ledId = {
    QR_READER: 1,
    BANKNOTE_ACCEPTOR: 2,
    BANKNOTE_DISPENSER: 2,
    PRINTER: 3,
}
