import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function getBanknoteEvents({ page, limit }) {
    return localHttpApi.callApi({
        path: "/banknoteEvents",
        method: "GET",
        params: {
            page,
            limit,
        }
    });
}
