import React from 'react'
import "./Button.scss"
import classNames from 'classnames';

const buttonTypes = {
    primary: 'primary',
    secondary: 'secondary',
    red: 'red',
    green: 'green',
}

export default function Button({className, onClick, children, type, ...props}) {
  return (
      <button
        className={classNames('Button', `${type || 'primary'}`, className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
  )
}

Button.type = buttonTypes;
