import join from 'url-join';
import qs from 'qs';
import {getLocalApiUrl} from "./config";


export default class LocalHttpApi {

    static getInstance(){
        if(!LocalHttpApi.instance){
            LocalHttpApi.instance = new LocalHttpApi();
        }
        return LocalHttpApi.instance;
    }

    constructor() {
        this.host = getLocalApiUrl();
    }


    async callApi({path, method='GET', params}) {

        let url = join( this.host, path);
        method = method.toUpperCase();
        let body = null;

        if(method === 'GET' && typeof params === 'object'){
            url += '?' + qs.stringify(params);
        }
        else if((method === 'POST' || method === 'PUT') && typeof params === 'object'){
            body = JSON.stringify(params);
        }


        return fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body
        })
            .then((response) => {

                if( response.status >= 400 ){
                    throw new Error('Error communicating with local API');
                }

                return response.json();
            });

    }


}
