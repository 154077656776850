import React from 'react'
import "./CurrentLimit.scss"
import warning from "../../assets/images/warning-limit.png"
import Button from "../../components/Button/Button"
import { useTranslation } from 'react-i18next';

export default function CurrentLimit(
  {showIcon, showButton, children, onIncrease}
) {
  const { t } = useTranslation();

  return (
    <div className='CurrentLimit'>
      {
        showIcon &&
        <img src={warning} alt="warning" />
      }
      <div className='text'>
        {children}
      </div>
      {
        showButton &&
        <Button
          className="button-growth"
          onClick={onIncrease}
        >
          <span className='button-text'>
            {t('Increase your limit!')} 
          </span> 
        </Button>
      }
    </div>
  )
}
