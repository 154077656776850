
export const urlLayout = {
    default: [
        "1 2 3 4 5 6 7 8 9 0 {bksp}",
        "q w e r t y u i o p",
        "a s d f g h j k l",
        "{shift} z x c v b n m , . / {shift}",
        "{space} : // @ - _ +"
    ],
    shift: [
        "! @ # $ % ^ & * ( ) {bksp}",
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{shift} Z X C V B N M < > ? {shift}",
        "{space} ; %3A %2F %2F %40 [ ] { }"
    ]
};
