import React, { useCallback } from 'react'
import "./CountryCodes.scss"
import _ from "lodash"

export default function CountryCodes({prefix, codesList, onSelected, onFocus}) {

  const handleSelected = useCallback((code) => {
    onSelected(code);
  }, [onSelected]);
  
  return (
    <div className='CountryCodes'>
      <div className='prefix-number'>
        <div className='prefix'>
          +
        </div>
        <input
          type="text"
          value={prefix}
          onChange={handleSelected}
          onFocus={onFocus}
        />
      </div>
      {
        codesList.length > 0  &&
        <div className='codes-list'>
          {
            _.map(codesList, (code, index) => (
              <div
                key={index}
                className='item'
                onClick={() => handleSelected(code)}
              >
                {code.isoCode} (+{code.countryCode})
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}
