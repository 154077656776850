import React, {useEffect, useState} from 'react';
import moment from 'moment';
import './QrMock.scss';
import MockPanel from "../MockPanel/MockPanel";
import HistoryPanel from "../HistoryPanel/HistoryPanel";
import LocalWsApi from "../../../../services/LocalWsApi";

const QrMock = () => {

    const [reads, setReads] = useState([]);
    const [input, setInput] = useState('');


    useEffect(()=>{

        const wsApi = LocalWsApi.getInstance();

        const readCallback = ( data ) => {
            console.log('Receive QR read: ' + data);
            setReads(reads=>[{
                time: moment(),
                data
            }, ...reads]);
        }

        wsApi.subscribe('qrReader.read', readCallback);
        return () => {
            wsApi.unsubscribe('qrReader.read', readCallback);
        }
    },[]);

    const handleSend = () => {
        const host = 'http://' + window.location.hostname +':13100';
        fetch(host + '/qr_read?qr=' + input)
            .then( res => res.json() );
    }

    return (
        <MockPanel className={"QrMock"}>
            <h3 className='panel-header'>QR Reader</h3>
            <hr/>
            <p>Ingresa texto en el campo y selecciona enviar. Se simulará que el texto fue leído por el lector QR</p>
            <div className='input-block'>
                <input value={input} onChange={e=>setInput(e.target.value)} className={'qr-input'} placeholder={"Contenido del QR"} />
                <button onClick={handleSend} className={'send-btn'} >Enviar</button>
            </div>
            <p>Historial</p>
            <HistoryPanel>
            {reads.map( (read, index) => (
                <div className='read-item' key={index}>
                    <span className={'time'} >{read.time.format( 'HH:mm:ss' )}</span>{' '}
                    <span className='data'>{read.data}</span>
                </div>))}
            </HistoryPanel>
        </MockPanel>
    );
};

export default QrMock;
