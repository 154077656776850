import LocalWsApi from "../LocalWsApi";
import {btmLog} from "../localBackendService";
import {getSetting, settingKey} from "../appSettings";


export const banknoteRecyclerEvents = {
    //Only on javascript
    READY: 'banknoteRecycler.ready',
    DISCONNECTED: 'banknoteRecycler.disconnected',
    RETURNED: 'banknoteRecycler.returned',

    //Events sent by java to the websocket and may be re-fired by javascript on the BanknoteRecycler EventEmitter
    ESCROW: 'banknoteRecycler.escrow',
    REJECTED: 'banknoteRecycler.rejected',
    DISPENSED: 'banknoteRecycler.dispensed',
    RETRIEVED: 'banknoteRecycler.retrieved',
    STACKED: 'banknoteRecycler.stacked',
    STATE_CHANGED: 'banknoteRecycler.stateChanged',
};

export const banknoteRecyclerStatus = {
    INITIALIZE: 'INITIALIZING',//Device is initializing, we wait
    DISABLED: 'HOST_DISABLED',//Device waiting for commands
    IDLE: 'IDLE',//Device waiting for user to insert a banknote
    ESCROWED: 'ESCROWED',
    ACCEPTING: 'ACCEPTING',//Device is accepting banknotes
}

const controllerPrefix = 'banknoteRecycler/';
const controllerMockPrefix = 'banknoteRecyclerMock/';

export default class BanknoteRecyclerClient{

    constructor() {
        this.wsApi = LocalWsApi.getInstance();
        this.listeners = {};
    }

    on(event, listener) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }

        this.listeners[event].push(listener);

        //Subscribe only once to the event cause all the listeners will be called in the emit function
        if (this.listeners[event].length === 1) {
            this.wsApi.subscribe(event, this.emit.bind(this, event));
        }
    }

    off(event, listener) {
        if (!this.listeners[event]) {
            return;
        }

        this.listeners[event] = this.listeners[event].filter(l => l !== listener);

        if (this.listeners[event].length === 0) {
            this.wsApi.unsubscribe(event, this.emit.bind(this, event));
            delete this.listeners[event];
        }
    }

    emit(event, data) {
        if (!this.listeners[event]) {
            return;
        }
        this.listeners[event].forEach(listener => listener(data));
    }

    async getStatus(){
        return this.wsApi.callApi({path: controllerPrefix + 'getStatus'});
    }

    async startEscrowForUser(){
        let acceptedBanknotes = await getSetting( settingKey.ACCEPTED_BANKNOTES );
        acceptedBanknotes = JSON.parse(acceptedBanknotes);

        //First note should be 500
        //To make minimum configurable, change this to the corresponding banknote

        const handleFirstNote = ({denomination}) => {
            if(denomination !== 500){
                btmLog('ERROR BanknoteRecyclerClient - startEscrowForUser - first note should be 500, but was ' + denomination);
            }
            this.off(banknoteRecyclerEvents.ESCROW, handleFirstNote);
            btmLog('Re-starting escrow for all accepted banknotes: ' + acceptedBanknotes);
            this.startEscrow(acceptedBanknotes);
        }

        this.on(banknoteRecyclerEvents.ESCROW, handleFirstNote);

        return this.startEscrow([500]);
    }

    async getAcceptedBanknotes(){
        let acceptedBanknotes = await getSetting( settingKey.ACCEPTED_BANKNOTES );
        return JSON.parse(acceptedBanknotes);
    }

    async startEscrowForARefill(){
        let acceptedBanknotes = await getSetting( settingKey.RECYCLING_BANKNOTES );
        acceptedBanknotes = JSON.parse(acceptedBanknotes);

        return this.startEscrow( acceptedBanknotes );
    }

    async startEscrow( acceptedBanknotes ){
        const status = await this.getStatus();
        if(status === banknoteRecyclerStatus.DISABLED){
            return this.wsApi.callApi({path: controllerPrefix + 'startEscrow', fireAndForget: true, params: acceptedBanknotes});
        }
        else if( status === banknoteRecyclerStatus.IDLE || status === banknoteRecyclerStatus.ESCROWED || status === banknoteRecyclerStatus.ACCEPTING ){
            await btmLog('BanknoteRecyclerClient - startEscrow - already started');
            return this.wsApi.callApi({path: controllerPrefix + 'startEscrow', fireAndForget: true, params: acceptedBanknotes});
        }
        else{
            throw new Error('BanknoteRecyclerClient - startEscrow - unknown status: ' + status);
        }
    }

    async stopEscrow(){
        return this.wsApi.callApi({path: controllerPrefix + 'stopEscrow', fireAndForget: true});
    }

    async floatDownAll(){
        return this.wsApi.callApi({path: controllerPrefix + 'floatDownAll', fireAndForget: true});
    }

    async dispense(amount){
        return this.wsApi.callApi({path: controllerPrefix + 'dispense', params: amount, fireAndForget: true});
    }

    async stackDocument(){
        return this.wsApi.callApi({path: controllerPrefix + 'stackDocument', fireAndForget: true});
    }

    removeAllListeners() {
        for (let event in this.listeners) {
            if (this.listeners.hasOwnProperty(event)) {
                this.wsApi.unsubscribe(event, this.emit.bind(this, event));
            }
        }

        this.listeners = {};
    }

    mockSimulateBanknoteInserted( value ){
        return this.wsApi.callApi({path: controllerMockPrefix + 'insertBanknote', params: value});
    }

}
