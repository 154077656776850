import React from 'react'
import "./Checkbox.scss"

export default function Checkbox(
  { checked, onChange }
) {
  return (
    <div className='Checkbox'>
      <input type="checkbox" checked={checked} onChange={onChange} />
    </div>
  )
}
