import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();


export async function createTransaction( transactionInfo ) {

    const { amount, type, username, notes, txId } = transactionInfo;

    return localHttpApi.callApi({
        path: "/transactions",
        method: "POST",
        params: { amount, type, username: username ? username : 'n/a', notes, txId },
    });
}

