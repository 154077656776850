import LocalWsApi from "./LocalWsApi";
import LocalHttpApi from "./LocalHttpApi";

const localWsApi = LocalWsApi.getInstance()
const localHttpApi = LocalHttpApi.getInstance()

const controllerPrefix = 'securityCamera/';

export async function getConnectedCameras() {
    return localHttpApi.callApi({
        path:'/securityCamera/getConnectedCameras',
        method: 'GET'
    });
}

async function _startRecording({sessionId = ''}) {
    const response = await localWsApi.callApi({
        path: controllerPrefix + 'startRecording',
        params: {outputFilename: sessionId}
    });

    if (response?.error) {
        throw new Error(response.error);
    }

}

/**
 * Start recording video from the security camera
 * To prevent failing when the camera is closing the previous video, this method will retry 3 times
 * @param sessionId
 * @return {Promise<*>}
 */
export async function startRecording({sessionId = ''}) {
    let retries = 3;
    let success = false;
    // Retry 3 times
    while (retries > 0 && !success) {
        try {
            await _startRecording({sessionId});
            success = true;
        } catch (e) {
            retries--;
            if (retries === 0) {
                throw e;
            }
            await new Promise(resolve => setTimeout(resolve, 500));// sleep 500ms
        }
    }
}

export async function stopRecording({fireAndForget = true}) {
    return localWsApi.callApi({
        path: controllerPrefix + 'stopRecording',
        fireAndForget: fireAndForget,
    });
}
