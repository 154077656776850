import React, {useEffect} from 'react'
import loading from '../../assets/images/loading.svg'
import loadingError from '../../assets/images/loading_error.svg'
import "./Response.scss"
import { useLocation } from 'react-router-dom'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import Layout from '../../components/Layout/Layout'
import { secondsToTimer } from '../../services/constants'
import { useTranslation } from 'react-i18next';
import {btmLog} from "../../services/localBackendService";

export default function ResponseQrCode() {

  useEffect(() => {
    btmLog('ResponseQrCode screen').catch(console.error);
  }, []);

  const { state } = useLocation();
  const { t } = useTranslation();

  const { loader } = state || {};

  return (
    <Layout
      showHeader={true}
      showFooter={true}
      hideButtons={true}
    >
    <div className='Response'>
      <div className='d-flex justify-content-end'>
        <CircleTimer
          seconds={secondsToTimer}
        ></CircleTimer>
      </div>
      <div className='content'>
        {
          loader === 'dots' &&
          <div className='d-flex flex-column justify-content-center'>
            <img src={loading} alt="Cargando" className='loading-img'/>
            <div className='text-center'>
              <span className='loading-text'>
                {t('Loading')}
              </span>
            </div>
          </div>
        }

        {
          loader !== 'dots' &&
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
              <img src={loadingError} alt="Cargando"  className='loader-error'/>
            </div>
            <div className='title'>
              {t('QR Code doesn\'t exists')}
            </div>
            <div className='description'>
              {t('If problem persist contact us')}
            </div>
          </div>
        }
      </div>
    </div>
    </Layout>
  )
}
