import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  operator: null,
  //operator: {name: 'admin'},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.operator = action.payload;
    },
    logout(state) {
      state.operator = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
