import React, {useCallback, useEffect, useMemo} from 'react';
import './BanknoteRecyclerMock.scss';
import moment from "moment/moment";
import MockPanel from "../MockPanel/MockPanel";
import HistoryPanel from "../HistoryPanel/HistoryPanel";
import BanknoteRecyclerClient, {banknoteRecyclerEvents} from "../../../../services/hardware/BanknoteRecylcerClient";

const BanknoteRecyclerMock = () => {

    const [status, setStatus] = React.useState('NOT QUERIED');
    const [events, setEvents] = React.useState([]);

    const addEvent = useCallback(({time, message}) => {
        setEvents(reads => [{
            time,
            message
        }, ...reads]);
    }, [setEvents]);

    const recyclerClient = useMemo( ()=>new BanknoteRecyclerClient(), [] );


    //Set up all event listeners
    useEffect(()=>{

        const statusChangeCB  =(state) =>{
            setStatus(state);
            addEvent({ time: moment().format('HH:mm:ss'), message: "Cambio de status: "+state });
        }
        recyclerClient.on( banknoteRecyclerEvents.STATE_CHANGED, statusChangeCB);

        const returnedCB = ({denomination}) => {
            addEvent({ time: moment().format('HH:mm:ss'), message: "El cajero te escupe tu billete de regreso: "+denomination });
        }
        recyclerClient.on( banknoteRecyclerEvents.RETURNED, returnedCB);

        recyclerClient.on( 'banknoteRecyclerMock.mockAction', (data) => {
            addEvent({ time: moment().format('HH:mm:ss'), message: "Realizando acción: " + data });
        });

        return () => {
            recyclerClient.off( banknoteRecyclerEvents.STATE_CHANGED, statusChangeCB);
            recyclerClient.off( banknoteRecyclerEvents.RETURNED, returnedCB);
        }
    },[recyclerClient, addEvent]);


    //Get initial status
    const getStatus = useCallback(() => {
        recyclerClient.getStatus().then((response) => {
            console.log(response);
            setStatus(response);
        });
    },[recyclerClient]);

    useEffect(()=>{
        getStatus();
    },[getStatus]);

    //Simulate insertion
    const simulateInsertion = ( value ) => {
        addEvent({ time: moment().format('HH:mm:ss'), message: "Simulación de ingreso de billete: "+value });
        recyclerClient.mockSimulateBanknoteInserted( value ).then((response) => {
            if( response?.error ){
                throw response.error;
            }
        })
        .catch((error) => {
            addEvent({ time: moment().format('HH:mm:ss'), message: `Error. ${value}: ${error}` });
        });
    }


    return (
        <MockPanel className={"BanknoteRecyclerMock"}>
            <h3 className='panel-header'>Banknote Recycler</h3>
            <hr/>
            <p>Aquí puedes simular que metes billetes y también verás cuando el cajero los quiera entregar.</p>

            <div className='status'>Status: {status}</div>
            <p>Ingresar billete</p>

            <div className='action-container'>
                <button className={'action-btn'} onClick={()=>simulateInsertion(20)}>$20</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion(50)}>$50</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion(100)}>$100</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion(200)}>$200</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion(500)}>$500</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion(1000)}>$1,000</button>
                <button className={'action-btn'} onClick={()=>simulateInsertion('fake')}>Falso</button>
            </div>
            <p>Historial</p>
            <HistoryPanel>
                {events.map((event, index) => (
                    <div key={index}>
                        <span className='time'>{event.time}</span>{' '}
                        <span className='data'>{event.message}</span>
                    </div>
                ))}
            </HistoryPanel>
        </MockPanel>
    );
};

export default BanknoteRecyclerMock;
