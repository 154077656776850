
export const formatMoney = (number)=>{
    const roundedNumber = Number(Math.round(number + 'e2') + 'e-2');
    const roundedString = roundedNumber.toFixed(2);
    // Add comma separation for thousands
    const parts = roundedString.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}


//cryptographically secure random number generator
export const generateRandomNumberString = (length)=>{
    let array = new Uint32Array(length);
    window.crypto.getRandomValues(array);
    let output = '';
    for (let i = 0; i < array.length; i++) {
        output += array[i].toString().slice(-1); // Using the last digit from each number to ensure we only get digits
    }
    return output;
}

/**
 * Formats an array of banknotes into a string with the dollar sign prefixed to each note and separated by comma.
 *
 * @param {Array<number>} bankNotes - The array of banknotes to be formatted.
 * @returns {string} - The formatted string of banknotes.
 */
export const formatBankNotes = (bankNotes) => {
    return bankNotes.map(note => `$${note}`).join(', ');
};

export const asteriskPhoneNumber = ( str ) => {
    return str.substring(0, 3) + "*".repeat(str.length - 6) + str.substring(str.length - 3);
}
