import React, {useCallback, useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { paths } from '../../services/routes/appRoutes';
import "./PrintTicket.scss"
import Layout from '../../components/Layout/Layout';
import Title from '../../components/Title/Title';
import { useTranslation } from 'react-i18next';
import ticket from "../../assets/images/ticket.gif"
import {useSelector} from "react-redux";
import TicketPrinterClient from "../../services/hardware/TicketPrinterClient";
import {AlertContext} from "../../components/Alerts/AlertContext";
import {btmLog} from "../../services/localBackendService";
import {logSessionEvent} from "../../services/localSessionService";
import {sessionEvents} from "../../utils/sessionUtils";

export default function PrintTicket() {

  useEffect(() => {
    btmLog('PrintTicket screen').catch(console.error);
  }, []);

  const {addAlert} = useContext(AlertContext);
  const session = useSelector((state) => state.session);
  const { ticketData } = session;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = useCallback(() => {
    navigate(paths.home);
  }, [navigate])

  const printTicket = useCallback(async () => {
    try {
      await (new TicketPrinterClient()).printReceiveAddressTicket(ticketData)
    } catch (e) {
        const errStr = `Error printing ticket: ${e}`;
      logSessionEvent(session.sessionId, sessionEvents.ERROR, {step: "PrintTicket", critical: false, error: errStr });
      await btmLog(errStr);
      addAlert({type: 'error', message: t('An error occurred while printing ticket')});
      throw e;
    }
  }, [addAlert, t, ticketData, session]);

  useEffect(() => {
    printTicket().catch(console.error);
    setTimeout(() => {
      navigate(paths.success);
    }, 10000);
  }, [navigate, printTicket])

  return (
    <Layout
      showHeader={true}
      showFooter={false}
      backButtonCallback={onBack}
      hideButtons={false}
    >
      <div
        className='PrintTicket'
      >
        <div className='title text-center'>
          <Title
            text={t('Printing ticket')}
          ></Title>
        </div>
        <div className='content'>
          <img src={ticket} alt="ticket" />
        </div>
      </div>
    </Layout>
  )
}
