import React, {useEffect, useState} from 'react'
import "./Header.scss"
import logo from "../../assets/images/logo_cryptobox.svg";
import {dateFormatter, timeFormatter} from './HeaderUtils';
import {useNavigate} from "react-router-dom";
import {paths} from "../../services/routes/appRoutes";

export default function Header({allowAdminScreen = false}) {

    const navigate = useNavigate();

    // Keep the date updated
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    //Listen to the admin screen navigation
    const [clickCount, setClickCount] = useState(0);
    useEffect(() => {

        if (!allowAdminScreen) {
            return;
        }

        let timer;
        if (clickCount === 9) {
            navigate(paths.login);

        } else if (clickCount > 0) {

            timer = setTimeout(() => {
                setClickCount(0);
            }, 3000);

        }
        return () => clearTimeout(timer);
    }, [clickCount, navigate, allowAdminScreen]);

    const handleClick = () => {
        setClickCount(prevCount => prevCount + 1);
    };


    return (
        <div className='Header'>
            <div className="d-flex justify-content-between">
                <div onClick={handleClick}>
                    <img src={logo} alt="logo"/>
                </div>
                <div className="the-date">
                    <div className='text-end'>
                        {dateFormatter(date.toISOString())}
                        <br/>
                        {timeFormatter(date.toISOString())}
                    </div>
                </div>
            </div>
        </div>
    )
}
