import React from 'react'
import "./Title.scss"

export default function Title({text, children}) {
  return (
    <h2 className='Title'>
      {text}
      {children}
    </h2>
  )
}
