import React, {useContext, useEffect, useState} from 'react';
import './BanknoteEvents.scss';
import Layout from "../../../components/Layout/Layout";
import {moneyFormatter} from "../../../services/currencyUtils";
import {paths} from "../../../services/routes/appRoutes";
import {useNavigate} from "react-router-dom";
import {getBanknoteEvents} from "../../../services/frontBanknoteEventService";
import {AlertContext} from "../../../components/Alerts/AlertContext";
import moment from "moment";
import arrow from "../../../assets/images/arrow_back_wallet.png";
import Button from "../../../components/Button/Button";
import {btmLog} from "../../../services/localBackendService";
import {logSessionEvent} from "../../../services/localSessionService";
import {sessionEvents} from "../../../utils/sessionUtils";
import {useSelector} from "react-redux";

const BanknoteEvents = () => {

    const navigate = useNavigate();
    const {addAlert} = useContext(AlertContext);
    const [page, setPage] = useState(1);
    const [tableMeta, setTableMeta] = useState({});
    const [banknoteEvents, setBanknoteEvents] = useState([]);
    const {sessionId} = useSelector((state) => state.session);

    useEffect(() => {
        btmLog('BanknoteEvents screen');
        logSessionEvent(sessionId, sessionEvents.ADMIN_NAV, { screen: "ManageCashbox" });
    }, [sessionId]);

    const limit = 12;

    useEffect(() => {
        getBanknoteEvents({page, limit})
            .then(({data, meta}) =>{
                setBanknoteEvents(data) ;
                setTableMeta(meta);
            })
            .catch(e => addAlert({message: 'Error al obtener eventos. ' + e.message, type: 'error'}));
    }, [addAlert, page]);

    const nextPage= () => {
        setPage(page+1);
    }

    const prevPage= () => {
        setPage(page-1);
    }

    return (
        <Layout className={"BanknoteEvents"} showFooter={false}>
            <div className='content'>
                <div>
                    <h3>
                        Eventos de la caja
                    </h3>
                </div>

                <div className='table-container'>

                    <table className={'table'}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha</th>
                            <th>Evento</th>
                            <th>Valor</th>
                            <th>Balance</th>
                        </tr>
                        </thead>
                        <tbody>
                        {banknoteEvents.map((banknoteEvent) => (
                            <tr key={banknoteEvent.id}>
                                <td>{banknoteEvent.id}</td>
                                <td>{moment(banknoteEvent.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td>{banknoteEvent.type}</td>
                                <td>$ {moneyFormatter(banknoteEvent.denomination)}</td>
                                <td>$ {moneyFormatter(banknoteEvent.totalBalance)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className={'pagination-container'}>
                        <button className='pagination-button left' disabled={ page===1 } onClick={prevPage}>
                            <img className={'btn-arrow'} src={arrow} alt={'arrow left'}/>
                        </button>

                        <span className={'pagination-text'}>Página {page} de {Math.ceil(tableMeta.totalItems / limit)}</span>

                        <button className='pagination-button right' disabled={ page===Math.ceil(tableMeta.totalItems / limit) } onClick={nextPage}>
                            <img className={'btn-arrow'} src={arrow} alt={'arrow right'}/>
                        </button>

                    </div>

                </div>

                <Button type={Button.type.secondary} className='return-button' onClick={()=>navigate(paths.adminMenu)}>
                    Regresar
                </Button>


            </div>
        </Layout>
    );
};

export default BanknoteEvents;
