import React from 'react'
import "./Wallet.scss"
import walletIcon from "../../assets/images/wallet_icon.svg"
import { useTranslation } from 'react-i18next';

export default function Wallet({ address }) {
  const {t} = useTranslation();

  return (
    <div className='Wallet'>
      <div className='wallet-icon'>
        <img src={walletIcon} alt="icon" />
      </div>
      <div className='wallet-text'>
        {t('Your wallet')}
      </div>
      <div className='wallet-code'>
        <span className='wallet-code-text'>
            {address}
        </span>
      </div>
    </div>
  )
}
