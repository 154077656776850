import {getSetting, settingKey} from "./appSettings";
import {decodeJWT} from "../utils/jwt";
import BanknoteRecyclerClient, {banknoteRecyclerStatus} from "./hardware/BanknoteRecylcerClient";
import QrReaderClient from "./hardware/QrReaderClient";
import TicketPrinterClient from "./hardware/TicketPrinterClient";
import {sleep} from "../utils/jsUtils";
import {btmLog} from "./localBackendService";

export const checkAuth = async () => {
    const jwt = await getSetting(settingKey.AUTH_JWT);
    if( !jwt )
        throw new Error('El servidor no ha sido registrado.');

    const decoded = decodeJWT(jwt);
    if( !decoded )
        throw new Error('El token de registro no es válido.');

    const now = new Date();
    if( now.getTime() > decoded.exp * 1000 )
        throw new Error('El token de registro ha expirado.');

    return true;
}


export const checkHardware = async () => {

    await checkBanknoteRecycler();

    const qrReader = new QrReaderClient();
    const qrStatus = await qrReader.getStatus();

    if( qrStatus !== 'OK' )
        throw new Error(`El lector de QR no está listo. Status: ${qrStatus}`);

    const ticketPrinter = new TicketPrinterClient();
    const tpStatus = await ticketPrinter.getStatus();

    if( tpStatus !== 'OK' )
        throw new Error(`La impresora de tickets no está lista. Status: ${tpStatus}`);

    return true;
}

const checkBanknoteRecycler = async () => {

    const banknoteRecycler = new BanknoteRecyclerClient();
    let bnrStatus;
    try {
        bnrStatus = await banknoteRecycler.getStatus();
    }
    catch (e) {
        throw new Error('El reciclador de billetes no está listo. Status: ' + e.message);
    }

    if( bnrStatus === banknoteRecyclerStatus.DISABLED )
        return true;

    if( bnrStatus === banknoteRecyclerStatus.INITIALIZE ){
        await sleep(2000);//Wait the device to initialize
        return await checkBanknoteRecycler();
    }

    if( bnrStatus === banknoteRecyclerStatus.ESCROWED ){
        await btmLog('BanknoteRecyclerClient - Found on ESCROWED status. Stopping escrow');
        await banknoteRecycler.stackDocument();
        await sleep(4000);//Wait for escrow to stop before checking the status again
        await checkBanknoteRecycler();
        return true;
    }

    if( bnrStatus === banknoteRecyclerStatus.IDLE ){
        await btmLog('BanknoteRecyclerClient - Found on IDLE status. Stopping escrow');
        await banknoteRecycler.stopEscrow();
        await sleep(3000);//Wait for escrow to stop before checking the status again
        await checkBanknoteRecycler();
        return true;
    }

    throw new Error(`El reciclador de billetes no está listo. Status: ${bnrStatus}`);


}
