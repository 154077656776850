import React, {useCallback, useEffect} from 'react'
import "./TransactionLimitAlert.scss"
import FullScreenAlert from '../../components/FullScreenAlert/FullScreenAlert'
import CircleTimer from '../../components/CircleTimer/CircleTimer'
import { secondsToTimer } from '../../services/constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { paths } from '../../services/routes/appRoutes'
import infoRedAnimated from '../../assets/images/info-red-animated.gif'
import CurrentLimit from '../../components/CurrentLimit/CurrentLimit'
import Title from '../../components/Title/Title'
import Button from '../../components/Button/Button'
import {useSelector} from "react-redux";
import {formatMoney} from "../../utils/numberUtils";
import {btmLog} from "../../services/localBackendService";

export default function TransactionLimitAlert() {

  useEffect(() => {
    btmLog('TransactionLimitAlert screen').catch(console.error);
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {kycData} = useSelector((state) => state.session);
  const currentLimit = kycData?.amountLimitRemaining;

  const onBack = useCallback(() => {
    navigate(paths.home);
  }, [navigate])

  return (
    <FullScreenAlert>
      <div className='TransactionLimitAlert'>
        <div className='d-flex justify-content-end'>
          <CircleTimer
            seconds={secondsToTimer}
            callback={onBack}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <img src={infoRedAnimated} alt="" className='info-image'/>
        </div>
        <div className='d-flex justify-content-center'>
          <div>
            <CurrentLimit
              showIcon={false}
              showButton={false}
            >
              {t('Current transaction limit is: ', {amount: '$' + formatMoney(currentLimit)})}
            </CurrentLimit>
          </div>
        </div>
        <div className='d-flex justify-content-center title'>
          <Title
            text={t('Transaction limit reached')}
          ></Title>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='subtitles subtitle-1'>
            {t('We are sorry, but you\'ve reached your transaction limit for today')}
          </div>
        </div>
        <div className='subtitles subtitle-2'>
          {t('We look forward to seeing you back soon to continue operating with us')}
        </div>
        <div className='d-flex justify-content-center'>
          <Button className="button-finish" onClick={onBack}>
            {t('Finish')}
          </Button>
        </div>
      </div>
    </FullScreenAlert>
  )
}
