import LocalHttpApi from "./LocalHttpApi";

const localHttpApi = LocalHttpApi.getInstance();

export async function reboot({ operatorName }) {
    return localHttpApi.callApi({
        path: "/commands/reboot",
        method: "POST",
        params: { operatorName },
    });
}
