import React, { useEffect, useRef, useState } from 'react';
import './TextInput.scss';
import classNames from "classnames";
import Keyboard from "react-simple-keyboard";

const TextInput = ({ value, onChange, className, layout, ...props }) => {
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const keyboardRef = useRef(null);
    const keyboardWrapperRef = useRef(null);
    const [layoutName, setLayoutName] = useState("default");
    const inputRef = useRef(null);

    const onKeyPress = (button) => {
        switch (button) {
            case "{shift}":
            case "{lock}":
                setLayoutName(layoutName === "default" ? "shift" : "default");
                break;
            case "{enter}":
                setIsKeyboardVisible(false);
                break;
            default:
                break;
        }
    };

    const handleClickOutside = (event) => {
        if (keyboardWrapperRef.current &&
            !keyboardWrapperRef.current.contains(event.target) &&
            inputRef.current &&
            !inputRef.current.contains(event.target)
        ) {
            setIsKeyboardVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, []);

    const onChangeInput = (event) => {
        const input = event.target.value;
        onChange(input);
        keyboardRef.current?.setInput(input);
    };

    useEffect(() => {
        keyboardRef.current?.setInput(value);
    }, [value]);


    return <>
            <input
                {...props}
                value={value}
                onChange={onChangeInput}
                onFocus={() => setIsKeyboardVisible(true)}
                ref={inputRef}
                className={classNames('TextInput', className)}
            />
            {isKeyboardVisible && (
                <div ref={keyboardWrapperRef} className='rayyo-keyboard'>
                    <Keyboard
                        keyboardRef={r=>keyboardRef.current=r}
                        layoutName={layoutName}
                        onInit={k=> k.setInput(value)}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        layout={layout}
                    />
                </div>
            )}
        </>;
};

export default TextInput;
