import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./PagesList.scss"
import Alert from '../../scenes/Alert/Alert'
import ShadowAlert from '../../scenes/ShadowAlert/ShadowAlert'

export default function PagesList() {

  const [showAlert, setShowAlert] = useState(false)
  const [showShadowAlert, setShowShadowAlert] = useState(false)

  return (
    <div className='PagesList'>
      <ul className='list-group list-group-flush'>
        <li className='list-group-item'>
          <Link to="/home" relative="path">
            Home
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/buy" relative="path">
            BuyBitcoin
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/buy-confirm" relative="path">
            BuyBitcoinConfirm
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/enter-phone-number" relative="path">
            EnterPhoneNumber
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/print-ticket" relative="path">
            PrintTicket
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/redeem" relative="path">
            RedeemBitcoin
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/sell" relative="path">
            SellBitcoin
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/send-bitcoin" relative="path">
            SendBitcoin
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/take-your-ticket" relative="path">
            Take Your ticket
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/verification-code" relative="path">
            VerificationCode
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/response" relative="path">
            Response
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/response-qr-code" relative="path">
            Response QR CODE
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/response-transaction" relative="path">
            Response Transaction
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/admin/login" relative="path">
            Login
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/terms" relative="path">
            Términos
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/increase-limit" relative="path">
            Aumenta tu límite
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/transaction-limit" relative="path">
            Límite de transacción
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/transaction-limit-alert" relative="path">
            Límite de transacción Alert
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/transaction-verification" relative="path">
            Verificación de transacción
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/success" relative="path">
            Success
          </Link>
        </li>
        <li className='list-group-item'>
          <Link to="/success-redemption" relative="path">
            Success Redemption
          </Link>
        </li>
      </ul>
      <ul className='list-group list-group-flush'>
        <li className='list-group-item' onClick={() => setShowAlert(true)}>
          Alert
        </li>
        <li className='list-group-item' onClick={() => setShowShadowAlert(true)}>
          Shadow alert
        </li>
      </ul>
      {
        showAlert &&
        <Alert
          onClose={() => setShowAlert(false)}
        ></Alert>
      }
      {
        showShadowAlert &&
        <ShadowAlert
          onClose={() => setShowShadowAlert(false)}
        ></ShadowAlert>
      }
    </div>
  )
}
